import { Route, Switch } from "react-router-dom";
import MusicTwentySeventeen from "../../components/PreviousYears/2017/music2017";
import TwentySeventeenTopTen from "../../components/PreviousYears/2017/TwentySeventeenTopTen";
import TwentySeventeenTopTwenty from "../../components/PreviousYears/2017/TwentySeventeenTopTwenty";
import TwentySeventeenTopThirty from "../../components/PreviousYears/2017/TwentySeventeenTopThirty";
import TwentySeventeenTopForty from "../../components/PreviousYears/2017/TwentySeventeenTopForty";
import TwentySeventeenTopFifty from "../../components/PreviousYears/2017/TwentySeventeenTopFifty";
import SleptOn from "../../components/PreviousYears/2017/SleptOn";
import Singles from "../../components/PreviousYears/2017/Singles";
import Favorites from "../../components/PreviousYears/2017/Favorites";

const routes = (
  <Switch>
    <Route
      path="/components/PreviousYears/2017/music2017"
      component={MusicTwentySeventeen}
    />
    <Route
      path="/components/PreviousYears/2017/TwentySeventeenTopTen"
      component={TwentySeventeenTopTen}
    />
    <Route
      path="/components/PreviousYears/2017/TwentySeventeenTopTwenty"
      component={TwentySeventeenTopTwenty}
    />
    <Route
      path="/components/PreviousYears/2017/TwentySeventeenTopThirty"
      component={TwentySeventeenTopThirty}
    />
    <Route
      path="/components/PreviousYears/2017/TwentySeventeenTopForty"
      component={TwentySeventeenTopForty}
    />
    <Route
      path="/components/PreviousYears/2017/TwentySeventeenTopFifty"
      component={TwentySeventeenTopFifty}
    />
    <Route path="/components/PreviousYears/2017/SleptOn" component={SleptOn} />
    <Route path="/components/PreviousYears/2017/Singles" component={Singles} />
    <Route
      path="/components/PreviousYears/2017/Favorites"
      component={Favorites}
    />
  </Switch>
);

export default routes;
