import React from "react";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import ReactGA from "react-ga";
import RouteChangeTracker from "./components/RouteChangeTracker";
import ScrollTopArrow from "./components/GoTop";
import Navbar from "./components/Navbar/NavbarAlt";
import routes2023 from "./components/Routers/routes2023";
import routes2022 from "./components/Routers/routes2022";
import routes2021 from "./components/Routers/routes2021";
import routes2020 from "./components/Routers/routes2020";
import routes2019 from "./components/Routers/routes2019";
import routes2018 from "./components/Routers/routes2018";
import routes2017 from "./components/Routers/routes2017";
import routes2016 from "./components/Routers/routes2016";
import routes2015 from "./components/Routers/routes2015";
import routes2014 from "./components/Routers/routes2014";
import routes2013 from "./components/Routers/routes2013";
import MusicaObscura from "./components/Routers/routesObscura";

const TRACKING_ID = "UA-84369671-1";
ReactGA.initialize(TRACKING_ID);

class App extends React.Component {
  render() {
    return (
      <BrowserRouter>
        {/*         <header className="flex flex-row relative sticky font-display w-full pl-6 bg-black py-1 justify-end px-1">
          <Navbar />
        </header>
        <header className="flex flex-row fixed text-white font-display pl-6 py-2 justify-start px-1">
          KANE CURATED
        </header> */}
        <div className="font-display">
          {/* Routers */}
          {routes2023}
          {routes2022}
          {routes2021}
          {routes2020}
          {routes2019}
          {routes2018}
          {routes2017}
          {routes2016}
          {routes2015}
          {routes2014}
          {routes2013}
          {MusicaObscura}
        </div>
        <div>
          <ScrollTopArrow />
          <RouteChangeTracker />
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
