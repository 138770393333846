import React from "react";
import Album from "./Album/AlbumUnranked";
import Navbar from "../../Navbar/NavbarAlt";
import uuid from "uuid/v4";
import TopFavs from "../../../data/PreviousYears/2021/Favorites2021.json";
/* import dataJan from "../../data/PreviousYears/2021/Jan2021.json";
import dataFeb from "../../data/PreviousYears/2021/Feb2021.json";
import dataMar from "../../data/PreviousYears/2021/Mar2021.json";
import dataApr from "../../data/PreviousYears/2021/Apr2021.json";
import dataMay from "../../data/PreviousYears/2021/May2021.json";
import dataJun from "../../data/PreviousYears/2021/Jun2021.json";
import dataJul from "../../data/PreviousYears/2021/Jul2021.json";
import dataAug from "../../data/PreviousYears/2021/Aug2021.json";
import dataSep from "../../data/PreviousYears/2021/Sep2021.json";
import dataOct from "../../data/PreviousYears/2021/Oct2021.json";
import dataNov from "../../data/PreviousYears/2021/Nov2021.json";
import dataDec from "../../data/PreviousYears/2021/Dec2021.json";
//import fs from "fs";

let yearData = dataJan.concat(
  dataFeb,
  dataMar,
  dataApr,
  dataMay,
  dataJun,
  dataJul,
  dataAug,
  dataSep,
  dataOct,
  dataNov,
  dataDec
);

let starredArray = yearData.filter((e) => e.starred === 1 && e.yearRank === 0); */

//fs.writeFile("./starredArray.json", starredArray);

//console.log(starredArray);

class Favorites extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      albums: TopFavs,
    };
    //getEPList()
    //getStarred()
    //console.log(JSON.stringify(rankingArray));
  }

  render() {
    return (
      <div>
        <Navbar yearPrime="2021" key={uuid} />

        <div className="container">
          <div className="flex flex-row flex-wrap py-10">
            <main role="main" class="w-full sm:w-2/3 md:w-5/6 pt-10 px-10">
              <div className="font-display text-7xl">
                <div className="text-7xl text-center pb-10">
                  Favorites of 2021
                </div>
                <div className="text-xl">
                  In the past, I split great albums I enjoyed that didn't make
                  my top 50 into separate, genre-based lists. Now that I can
                  retrieve genre information via the Spotify API for each album,
                  I've combined all my favorite albums that didn't make the top
                  50 into a single list.
                </div>
                {/* ALBUM STARTS HERE */}
                <div className="text-left">
                  {this.state.albums.map((album) => (
                    <Album key={album.albumId} album={album} />
                  ))}
                </div>
              </div>
            </main>
            <aside className="w-full sm:w-1/3 md:w-1/6 px-2">
              <div className="fixed sm:top-20 md:top-10 p-4">
                <iframe
                  title="Favorites2021"
                  src="https://open.spotify.com/embed/playlist/6jiU8a653FTajPeVBk2pAM"
                  width="340"
                  height="800"
                  frameBorder="0"
                  allowtransparency="true"
                  allow="encrypted-media"
                ></iframe>
              </div>
            </aside>
          </div>
        </div>
      </div>
    );
  }
}

export default Favorites;
