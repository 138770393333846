import React from "react";
import Album from "./Albums/Singles";
import years from "../../../data/years.json";
import Navbar from "../../Navbar/Navbar";
import Albums from "../../../data/PreviousYears/2013/Singles2013.json";
//import getEPList from "../generateEPList";
//import getStarred from "../generateRankingList";

class Singles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      albums: Albums,
    };
    //getEPList()
    //getStarred()
    //console.log(JSON.stringify(rankingArray));
  }

  render() {
    return (
      <div className="flex justify-left bg-gray-50">
        <div className="flex flex-wrap justify-left font-display w-full pl-6">
          {years.map((year) => (
            <Navbar year={year} yearPrime="2013" key={year.year} />
          ))}
          <div className="flex w-5/5 pt-4 pr-40">
            <div className="fixed border-solid border-4 border-black-500">
              <iframe
                title="Singles2013"
                src="https://open.spotify.com/embed/playlist/4fDEDD4l61xDjVCQPA5S9S"
                width="340"
                height="760"
                frameBorder="0"
                allowtransparency="true"
                allow="encrypted-media"
              ></iframe>
            </div>

            <div className="pl-20 pt-40 ml-80">
              <div className="font-display">
                <div className="text-7xl text-left pb-10">Singles of 2013</div>
                <div className="text-xl">
                  This list of singles was cobbled together in 2022, since I
                  left much of 2013 unfinished when the pandemic took my
                  enthusiasm for this site. So its not as organically
                  constructed as the other lists I do each year. It's still good
                  tho.
                </div>

                {/* ALBUM STARTS HERE */}
                {this.state.albums.map((album) => (
                  <Album key={album.albumId} album={album} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Singles;
