import React from "react";
import Album from "./Album/AlbumUnranked";
import Navbar from "../Navbar/NavbarAlt";
import uuid from "uuid/v4";
//import TopFavs from "../../data/CurrentYear/Months/Favorites2022.json";
import dataJan from "../../data/CurrentYear/Months/Jan2022.json";
import dataFeb from "../../data/CurrentYear/Months/Feb2022.json";
import dataMar from "../../data/CurrentYear/Months/Mar2022.json";
import dataApr from "../../data/CurrentYear/Months/Apr2022.json";
import dataMay from "../../data/CurrentYear/Months/May2022.json";
import dataJun from "../../data/CurrentYear/Months/Jun2022.json";
import dataJul from "../../data/CurrentYear/Months/Jul2022.json";
import dataAug from "../../data/CurrentYear/Months/Aug2022.json";
import dataSep from "../../data/CurrentYear/Months/Sep2022.json";
import dataOct from "../../data/CurrentYear/Months/Oct2022.json";
import dataNov from "../../data/CurrentYear/Months/Nov2022.json";
//import fs from "fs";

let yearData = dataJan.concat(
  dataFeb,
  dataMar,
  dataApr,
  dataMay,
  dataJun,
  dataJul,
  dataAug,
  dataSep,
  dataOct,
  dataNov
);

let starredArray = yearData.filter((e) => e.starred === 1 && e.topFifty === 0);

//fs.writeFile("./starredArray.json", starredArray);

//console.log(starredArray);

class Favorites extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      albums: starredArray,
    };
    //getEPList()
    //getStarred()
    //console.log(JSON.stringify(rankingArray));
  }

  render() {
    return (
      <div>
        <Navbar yearPrime="2022" key={uuid} />

        <div className="container">
          <div className="flex flex-row flex-wrap py-10">
            <main role="main" class="w-full sm:w-2/3 md:w-5/6 pt-10 px-10">
              <div className="font-display text-7xl">
                <div className="text-7xl text-center pb-10">
                  Favorite Albums of 2022
                </div>
                <div className="text-xl">
                  As I mentioned on the Top Ten page, I enjoyed almost 150
                  albums this year, so this list of favorites is comprehensive
                  and full of amazing stuff. Don't neglect any of these. Many of
                  them were top 50 contenders up until the last minute.
                </div>
                {/* ALBUM STARTS HERE */}
                <div className="text-left">
                  {this.state.albums.map((album) => (
                    <Album key={album.albumId} album={album} />
                  ))}
                </div>
              </div>
            </main>
            <aside className="w-full sm:w-1/3 md:w-1/6 px-2">
              <div className="fixed sm:top-20 md:top-10 p-4">
                <iframe
                  title="Favorites2022"
                  src="https://open.spotify.com/embed/playlist/5cfFVf1sQUpEWUsNrqC7Q4"
                  width="340"
                  height="800"
                  frameBorder="0"
                  allowtransparency="true"
                  allow="encrypted-media"
                ></iframe>
              </div>
            </aside>
          </div>
        </div>
      </div>
    );
  }
}

export default Favorites;
