import { Route, Switch } from "react-router-dom";
import MusicTwentyTwentyThree from "../../components/PreviousYears/2023/music2023";
/* import TwentyTwentyTwoTopTen from "../../components/PreviousYears/2023/TwentyTwentyTwoTopTen";
import TwentyTwentyTwoTopTwenty from "../../components/PreviousYears/2023/TwentyTwentyTwoTopTwenty";
import TwentyTwentyTwoTopThirty from "../../components/PreviousYears/2023/TwentyTwentyTwoTopThirty";
import TwentyTwentyTwoTopForty from "../../components/PreviousYears/2023/TwentyTwentyTwoTopForty";
import TwentyTwentyTwoTopFifty from "../../components/PreviousYears/2023/TwentyTwentyTwoTopFifty"; */
import Dec from "../PreviousYears/2023/Months/Dec";
import Jan from "../PreviousYears/2023/Months/Jan";
import Feb from "../PreviousYears/2023/Months/Feb";
import Mar from "../PreviousYears/2023/Months/Mar";
import Apr from "../PreviousYears/2023/Months/Apr";
import May from "../PreviousYears/2023/Months/May";
import Jun from "../PreviousYears/2023/Months/Jun";
import Jul from "../PreviousYears/2023/Months/Jul";
import Aug from "../PreviousYears/2023/Months/Aug";
import Sep from "../PreviousYears/2023/Months/Sep";
import Oct from "../PreviousYears/2023/Months/Oct";
import Nov from "../PreviousYears/2023/Months/Nov";

const routes = (
  <Switch>
    <Route path="/components/PreviousYears/2023/Months/Dec" component={Dec} />
    <Route path="/components/PreviousYears/2023/Months/Jan" component={Jan} />
    <Route path="/components/PreviousYears/2023/Months/Feb" component={Feb} />
    <Route path="/components/PreviousYears/2023/Months/Mar" component={Mar} />
    <Route path="/components/PreviousYears/2023/Months/Apr" component={Apr} />
    <Route path="/components/PreviousYears/2023/Months/May" component={May} />
    <Route path="/components/PreviousYears/2023/Months/Jun" component={Jun} />
    <Route path="/components/PreviousYears/2023/Months/Jul" component={Jul} />
    <Route path="/components/PreviousYears/2023/Months/Aug" component={Aug} />
    <Route path="/components/PreviousYears/2023/Months/Sep" component={Sep} />
    <Route path="/components/PreviousYears/2023/Months/Oct" component={Oct} />
    <Route path="/components/PreviousYears/2023/Months/Nov" component={Nov} />
    <Route
      path="/components/PreviousYears/2023/music2023"
      component={MusicTwentyTwentyThree}
    />
    {/* <Route
      path="/components/PreviousYears/2023/TwentyTwentyTwoTopTen"
      component={TwentyTwentyTwoTopTen}
    />
    <Route
      path="/components/PreviousYears/2023/TwentyTwentyTwoTopTwenty"
      component={TwentyTwentyTwoTopTwenty}
    />
    <Route
      path="/components/PreviousYears/2023/TwentyTwentyTwoTopThirty"
      component={TwentyTwentyTwoTopThirty}
    />
    <Route
      path="/components/PreviousYears/2023/TwentyTwentyTwoTopForty"
      component={TwentyTwentyTwoTopForty}
    />
    <Route
      path="/components/PreviousYears/2023/TwentyTwentyTwoTopFifty"
      component={TwentyTwentyTwoTopFifty}
    /> */}
  </Switch>
);

export default routes;
