import React from "react";
import Album from "./Albums/AlbumUnranked";
import years from "../../../data/years.json";
import Navbar from "../../Navbar/Navbar";
import TopEPs from "../../../data/PreviousYears/2014/Favorites2014.json";
//import getEPList from "../generateEPList";
//import getStarred from "../generateRankingList";

class Favorites extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      albums: TopEPs,
    };
    //getEPList()
    //getStarred()
    //console.log(JSON.stringify(rankingArray));
  }

  render() {
    return (
      <div className="flex justify-left bg-gray-50">
        <div className="flex flex-wrap justify-left font-display w-full pl-6">
          {years.map((year) => (
            <Navbar year={year} yearPrime="2014" key={year.year} />
          ))}
          <div className="flex w-5/5 pt-4 pr-40">
            <div className="fixed border-solid border-4 border-black-500">
              <iframe
                title="Favorites2014"
                src="https://open.spotify.com/embed/playlist/5rTBTdT1K0BoRsLwPIkzNW"
                width="340"
                height="760"
                frameBorder="0"
                allowtransparency="true"
                allow="encrypted-media"
              ></iframe>
            </div>

            <div className="pl-20 pt-40 ml-80">
              <div className="font-display">
                <div className="text-7xl text-left pb-10">
                  Favorites of 2014
                </div>
                <div className="text-xl">
                  These albums almost made the Top 50. They're all great
                  listens.
                </div>

                {/* ALBUM STARTS HERE */}
                {this.state.albums.map((album) => (
                  <Album key={album.albumId} album={album} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Favorites;
