import React from "react";
import Album from "../Album";
import Navbar from "../../../Navbar/NavbarAlt";
import Navmonth from "../../../Navbar/Navmonth";
import months from "../../../months.json";
import uuid from "uuid/v4";
import albums from "../../../../data/PreviousYears/2023/Months/Jun2023.json";

class Jun extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      albums: albums,
    };
  }

  render() {
    return (
      <div className="">
        <Navbar yearPrime="2023" key={uuid} />
        <header className="flex flex-wrap fixed justify-center font-display w-full">
          {months.map((month) => (
            <Navmonth month={month} monthPrime="Jun" key={uuid} />
          ))}
        </header>

        <div className="container">
          <div className="flex flex-row flex-wrap py-10">
            <main role="main" class="w-full sm:w-2/3 md:w-5/6 pt-10 px-10">
              <div className="font-display text-7xl">
                <div className="text-7xl text-center pb-10">June 2023</div>
                <div className="text-xl">
                  {" "}
                  Some artists releasing albums I'm looking forward to:
                  Christine & The Queens, Dream Wife, Janelle Monáe, Jenny
                  Lewis, Killer Mike and Swans.
                </div>
                {/* ALBUM STARTS HERE */}
                <div className="text-left">
                  {this.state.albums.map((album) => (
                    <Album key={album.albumId} album={album} />
                  ))}
                </div>
              </div>
            </main>
            <aside className="w-full sm:w-1/3 md:w-1/6 px-2">
              <div className="fixed sm:top-20 md:top-10 p-4">
                <iframe
                  title="Jun2023"
                  src="https://open.spotify.com/embed/playlist/0qlcoycuMLb2znxkzC34TF"
                  width="340"
                  height="800"
                  frameBorder="0"
                  allowtransparency="true"
                  allow="encrypted-media"
                ></iframe>
              </div>
            </aside>
          </div>
        </div>
      </div>
    );
  }
}

export default Jun;
