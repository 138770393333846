import { Route, Switch } from "react-router-dom";
import MusicTwentySixteen from "../../components/PreviousYears/2016/music2016";
import TwentySixteenTopTen from "../../components/PreviousYears/2016/TwentySixteenTopTen";
import TwentySixteenTopTwenty from "../../components/PreviousYears/2016/TwentySixteenTopTwenty";
import TwentySixteenTopThirty from "../../components/PreviousYears/2016/TwentySixteenTopThirty";
import TwentySixteenTopForty from "../../components/PreviousYears/2016/TwentySixteenTopForty";
import TwentySixteenTopFifty from "../../components/PreviousYears/2016/TwentySixteenTopFifty";
import SleptOn from "../../components/PreviousYears/2016/SleptOn";
import Singles from "../../components/PreviousYears/2016/Singles";
import Favorites from "../../components/PreviousYears/2016/Favorites";

const routes = (
  <Switch>
    <Route
      path="/components/PreviousYears/2016/music2016"
      component={MusicTwentySixteen}
    />
    <Route
      path="/components/PreviousYears/2016/TwentySixteenTopTen"
      component={TwentySixteenTopTen}
    />
    <Route
      path="/components/PreviousYears/2016/TwentySixteenTopTwenty"
      component={TwentySixteenTopTwenty}
    />
    <Route
      path="/components/PreviousYears/2016/TwentySixteenTopThirty"
      component={TwentySixteenTopThirty}
    />
    <Route
      path="/components/PreviousYears/2016/TwentySixteenTopForty"
      component={TwentySixteenTopForty}
    />
    <Route
      path="/components/PreviousYears/2016/TwentySixteenTopFifty"
      component={TwentySixteenTopFifty}
    />
    <Route path="/components/PreviousYears/2016/SleptOn" component={SleptOn} />
    <Route path="/components/PreviousYears/2016/Singles" component={Singles} />
    <Route
      path="/components/PreviousYears/2016/Favorites"
      component={Favorites}
    />
  </Switch>
);

export default routes;
