import { Route, Switch } from "react-router-dom";
import MusicTwentyEighteen from "../../components/PreviousYears/2018/music2018";
import TwentyEighteenTopTen from "../../components/PreviousYears/2018/TwentyEighteenTopTen";
import TwentyEighteenTopTwenty from "../../components/PreviousYears/2018/TwentyEighteenTopTwenty";
import TwentyEighteenTopThirty from "../../components/PreviousYears/2018/TwentyEighteenTopThirty";
import TwentyEighteenTopForty from "../../components/PreviousYears/2018/TwentyEighteenTopForty";
import TwentyEighteenTopFifty from "../../components/PreviousYears/2018/TwentyEighteenTopFifty";
import SleptOn from "../../components/PreviousYears/2018/SleptOn";
import Singles from "../../components/PreviousYears/2018/Singles";
import Favorites from "../../components/PreviousYears/2018/Favorites";

const routes = (
  <Switch>
    <Route
      path="/components/PreviousYears/2018/music2018"
      component={MusicTwentyEighteen}
    />
    <Route
      path="/components/PreviousYears/2018/TwentyEighteenTopTen"
      component={TwentyEighteenTopTen}
    />
    <Route
      path="/components/PreviousYears/2018/TwentyEighteenTopTwenty"
      component={TwentyEighteenTopTwenty}
    />
    <Route
      path="/components/PreviousYears/2018/TwentyEighteenTopThirty"
      component={TwentyEighteenTopThirty}
    />
    <Route
      path="/components/PreviousYears/2018/TwentyEighteenTopForty"
      component={TwentyEighteenTopForty}
    />
    <Route
      path="/components/PreviousYears/2018/TwentyEighteenTopFifty"
      component={TwentyEighteenTopFifty}
    />
    <Route path="/components/PreviousYears/2018/SleptOn" component={SleptOn} />
    <Route path="/components/PreviousYears/2018/Singles" component={Singles} />
    <Route
      path="/components/PreviousYears/2018/Favorites"
      component={Favorites}
    />
  </Switch>
);

export default routes;
