import { Route, Switch } from "react-router-dom";
import MusicTwentyTwentyOne from "../../components/PreviousYears/2021/music2021";
import TwentyTwentyOneTopTen from "../../components/PreviousYears/2021/TwentyTwentyOneTopTen";
import TwentyTwentyOneTopTwenty from "../../components/PreviousYears/2021/TwentyTwentyOneTopTwenty";
import TwentyTwentyOneTopThirty from "../../components/PreviousYears/2021/TwentyTwentyOneTopThirty";
import TwentyTwentyOneTopForty from "../../components/PreviousYears/2021/TwentyTwentyOneTopForty";
import TwentyTwentyOneTopFifty from "../../components/PreviousYears/2021/TwentyTwentyOneTopFifty";
import EPs from "../../components/PreviousYears/2021/EPs";
import Covers from "../../components/PreviousYears/2021/Covers";
import Singles from "../../components/PreviousYears/2021/Singles";
import Favorites from "../../components/PreviousYears/2021/Favorites";

const routes = (
  <Switch>
    <Route
      path="/components/PreviousYears/2021/music2021"
      component={MusicTwentyTwentyOne}
    />
    <Route
      path="/components/PreviousYears/2021/TwentyTwentyOneTopTen"
      component={TwentyTwentyOneTopTen}
    />
    <Route
      path="/components/PreviousYears/2021/TwentyTwentyOneTopTwenty"
      component={TwentyTwentyOneTopTwenty}
    />
    <Route
      path="/components/PreviousYears/2021/TwentyTwentyOneTopThirty"
      component={TwentyTwentyOneTopThirty}
    />
    <Route
      path="/components/PreviousYears/2021/TwentyTwentyOneTopForty"
      component={TwentyTwentyOneTopForty}
    />
    <Route
      path="/components/PreviousYears/2021/TwentyTwentyOneTopFifty"
      component={TwentyTwentyOneTopFifty}
    />
    <Route path="/components/PreviousYears/2021/EPs" component={EPs} />
    <Route path="/components/PreviousYears/2021/Covers" component={Covers} />
    <Route path="/components/PreviousYears/2021/Singles" component={Singles} />
    <Route
      path="/components/PreviousYears/2021/Favorites"
      component={Favorites}
    />
  </Switch>
);

export default routes;
