import React from "react";
import Album from "./Albums/Album";
import Navbar from "../../Navbar/NavbarAlt";
import TopEPs from "../../../data/PreviousYears/2020/EPs2020.json";
import uuid from "uuid/v4";
//import getEPList from "../generateEPList";
//import getStarred from "../generateRankingList";

class EPs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      albums: TopEPs,
    };
    //getEPList()
    //getStarred()
    //console.log(JSON.stringify(rankingArray));
  }

  render() {
    return (
      <div>
        <Navbar yearPrime="2020" key={uuid} />

        <div className="container">
          <div className="flex flex-row flex-wrap py-10">
            <main role="main" class="w-full sm:w-2/3 md:w-5/6 pt-10 px-10">
              <div className="font-display text-7xl">
                <div className="text-7xl text-center pb-10">
                  Best EPs of 2020
                </div>
                <div className="text-xl">
                  I decided to separate my favorite EPs from albums. In past
                  years they kept getting lost in the list of best albums, so I
                  hope they stand out here.
                </div>
                {/* ALBUM STARTS HERE */}
                <div className="text-left">
                  {this.state.albums.map((album) => (
                    <Album key={album.albumId} album={album} />
                  ))}
                </div>
              </div>
            </main>
            <aside className="w-full sm:w-1/3 md:w-1/6 px-2">
              <div className="fixed sm:top-20 md:top-10 p-4">
                <iframe
                  title="EPs2020"
                  src="https://open.spotify.com/embed/playlist/6F9EfRD13nz53mlQ10eyAU"
                  width="340"
                  height="800"
                  frameBorder="0"
                  allowtransparency="true"
                  allow="encrypted-media"
                ></iframe>
              </div>
            </aside>
          </div>
        </div>
      </div>
    );
  }
}

export default EPs;
