import React from "react";
import Album from "./Albums/Album";
import { Link } from "react-router-dom";
import Navbar from "../../Navbar/NavbarAlt";
import uuid from "uuid/v4";
import albums from "../../../data/PreviousYears/2020/TopFifty2020.json";

let topTen = albums.slice(0, 10);

class TwentyTwentyTopTen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      albums: topTen,
    };
  }

  render() {
    return (
      <div>
        <Navbar yearPrime="2020" key={uuid} />

        <div className="container">
          <div className="flex flex-row flex-wrap py-10">
            <main
              role="main"
              class="w-full sm:w-2/3 md:w-5/6 md:pt-10 sm:pt-20 px-10"
            >
              <div className="font-display text-7xl">The Top Ten</div>
              <div className="text-xl">
                  <p>
                    This year was a journey. It is January 2021 and I feel like
                    I have not synthesized the music of 2020. I fell back on a
                    lot of comfort music, much of it from previous years. My
                    most listened to album was{" "}
                    <a
                      className="text-red-500"
                      href="https://pitchfork.com/reviews/albums/aldous-harding-designer/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Designer
                    </a>{" "}
                    by Aldous Harding, which is excellent, but was released in
                    March 2019. That being said, my top ten wasn't too difficult
                    to put together. But I'm not as sure about it as I have been
                    previous years. And the malaise that has been the hallmark
                    of my work during quarantine extends to my thoughts about
                    music from 2020. The entire year was a giant sociological
                    experiment, and the music that was released in 2020 fits
                    that. Experimental, introspective, lonely and dissaffected
                    music is what made my top fifty.
                  </p>
                  <p>
                    Some numbers: I listened to 151 albums and EPs, had 81
                    standout favorites that were whittled down to 50 (which
                    means you shouldn't sleep on anything that didn't make the
                    Top 50). I marked these favorites with a star &#9733;.
                    According to Spotify, I listened to over 92,000 minutes and
                    my favorite genre was Art Pop (for the 3rd year in a row).
                  </p>
                  <p>
                    I rated these albums before reading any top 10 lists. See
                    how my selections match up to the{" "}
                    <a
                      className="text-red-500"
                      href="https://www.nytimes.com/2020/12/02/arts/music/best-albums.html"
                      target="_blank"
                      rel="noreferrer"
                    >
                      NYTimes
                    </a>
                    ,{" "}
                    <a
                      className="text-red-500"
                      href="https://pitchfork.com/features/lists-and-guides/best-albums-2020/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Pitchfork
                    </a>
                    ,{" "}
                    <a
                      className="text-red-500"
                      href="https://www.npr.org/2020/12/02/930492613/the-50-best-albums-of-2020-page-19"
                      target="_blank"
                      rel="noreferrer"
                    >
                      NPR
                    </a>
                    ,{" "}
                    <a
                      className="text-red-500"
                      href="https://consequenceofsound.net/2020/12/top-50-albums-of-2020/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Consequence Of Sound
                    </a>
                    ,{" "}
                    <a
                      className="text-red-500"
                      href="https://www.stereogum.com/2108664/best-albums-2020/lists/year-in-review/2020-in-review/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Stereogum
                    </a>
                    ,{" "}
                    <a
                      className="text-red-500"
                      href="https://www.theguardian.com/music/2020/dec/01/the-50-best-albums-of-2020"
                      target="_blank"
                      rel="noreferrer"
                    >
                      The Guardian
                    </a>
                    , and{" "}
                    <a
                      className="text-red-500"
                      href="https://youtu.be/VN562bgJRZ4"
                      target="_blank"
                      rel="noreferrer"
                    >
                      The Needle Drop
                    </a>
                  </p>
                </div>
                <div className="pt-14 pb-10 text-xl text-center">
                  <div>
                    <Link
                      className="text-red-500"
                      to="/components/PreviousYears/2020/TwentyTwentyTopTen"
                    >
                      1 - 10
                    </Link>{" "}
                    |{" "}
                    <Link
                      className="hover:text-red-500"
                      to="/components/PreviousYears/2020/TwentyTwentyTopTwenty"
                    >
                      11 - 20
                    </Link>{" "}
                    |{" "}
                    <Link
                      className="hover:text-red-500"
                      to="/components/PreviousYears/2020/TwentyTwentyTopThirty"
                    >
                      21 - 30
                    </Link>{" "}
                    |{" "}
                    <Link
                      className="hover:text-red-500"
                      to="/components/PreviousYears/2020/TwentyTwentyTopForty"
                    >
                      31 - 40
                    </Link>{" "}
                    |{" "}
                    <Link
                      className="hover:text-red-500"
                      to="/components/PreviousYears/2020/TwentyTwentyTopFifty"
                    >
                      41 - 50
                    </Link>
                  </div>

                {/* ALBUM STARTS HERE */}
                {this.state.albums.map((album) => (
                  <Album key={album.albumId} album={album} />
                ))}
              </div>
            </main>
            <aside className="w-full sm:w-1/3 md:w-1/6 px-2">
              <div className="fixed sm:top-20 md:top-10 p-4 w-full">
                <iframe
                  title="TopTen2020"
                  src="https://open.spotify.com/embed/playlist/4NHOe3SJtF2Knap1LtewDV"
                  width="340"
                  height="800"
                  frameBorder="0"
                  allowtransparency="true"
                  allow="encrypted-media"
                ></iframe>
              </div>
            </aside>
          </div>
        </div>
      </div>
    );
  }
}

export default TwentyTwentyTopTen;
