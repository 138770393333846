import React from "react";
import { Link } from "react-router-dom";
import Album from "./Album/Album";
import Navbar from "../Navbar/NavbarAlt";
import uuid from "uuid/v4";
import TopFifty from "../../data/CurrentYear/2022Top50.json";
//import getEPList from "../generateEPList";
//import getStarred from "../generateRankingList";

let topTen = TopFifty.slice(0, 10);

class TwentyTwentyTwoTopTen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      albums: topTen,
    };
    //getEPList()
    //getStarred()
    //console.log(JSON.stringify(rankingArray));
  }

  render() {
    return (
      <div>
        <Navbar yearPrime="2022" key={uuid} />

        <div className="container">
          <div className="flex flex-row flex-wrap py-10">
            <main
              role="main"
              class="w-full sm:w-2/3 md:w-5/6 md:pt-10 sm:pt-20 px-10"
            >
              <div className="font-display text-7xl">The Top Ten</div>
              <div className="text-xl">
                I'm not sure what makes 2022 stand out. It felt, as a year,
                unremarkable. At least unremarkable in its unchanging
                strangeness. Musically the year started strong, and stayed
                strong. Unlike 2021, I spent my time keeping up-to-date, not
                listening to older stuff. My most listened to album was Aldous
                Harding's
                <a
                  className="text-red-500"
                  href="https://pitchfork.com/reviews/albums/aldous-harding-warm-chris/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  Warm Chris
                </a>{" "}
                from 2022 (ranked #15). I listened to more Metal and Country
                this year, but still returned to my favorite weirdos. I also
                decided to stop reviewing albums released in December. Those
                releases will get their due in 2023. I picked the title of this
                year's curation, Spanish for "I transform" from the song SAOKO,
                off of Rosalía's revelatory third album "
                <a
                  className="text-red-500"
                  href="https://pitchfork.com/reviews/albums/rosalia-motomami/"
                  target="_blank"
                  rel="noreferrer"
                >
                  MOTOMAMI
                </a>
                ."
                <br />
                Some numbers: I listened to 186 albums and EPs, had 148 standout
                favorites that were whittled down to 50 (which means you
                shouldn't sleep on anything that didn't make the Top 50). I
                marked these favorites with a star ★. According to Spotify, I
                listened to over 205,153 minutes and my favorite genre was Art
                Pop (for the 5th year in a row).
                <br />I rated these albums before reading any top album lists. I
                found my closest match in Pitchfork's list, but there was a lot
                of variation in top album lists this year (though not for the #1
                album). See how my selections match up to the{" "}
                <a
                  className="text-red-500"
                  href="https://www.nytimes.com/2022/11/30/arts/music/best-albums-renaissance-bad-bunny.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  NYTimes
                </a>
                ,{" "}
                <a
                  className="text-red-500"
                  href="https://pitchfork.com/features/lists-and-guides/best-albums-2022/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Pitchfork
                </a>
                ,{" "}
                <a
                  className="text-red-500"
                  href="https://www.npr.org/2022/12/12/1141591672/beyonce-renaissance-best-album-of-2022"
                  target="_blank"
                  rel="noreferrer"
                >
                  NPR
                </a>
                ,{" "}
                <a
                  className="text-red-500"
                  href="https://consequence.net/2022/12/top-50-albums-2022-list/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Consequence Of Sound
                </a>
                ,{" "}
                <a
                  className="text-red-500"
                  href="https://www.stereogum.com/2206970/best-albums-2022/lists/year-in-review/2022-in-review/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Stereogum
                </a>
                ,{" "}
                <a
                  className="text-red-500"
                  href="https://www.theguardian.com/music/2022/dec/06/the-50-best-albums-of-2022"
                  target="_blank"
                  rel="noreferrer"
                >
                  The Guardian
                </a>
                , and{" "}
                <a
                  className="text-red-500"
                  href="https://youtu.be/iHrJDmN2oxk"
                  target="_blank"
                  rel="noreferrer"
                >
                  The Needle Drop
                </a>
              </div>
              <div className="pt-14 pb-10 text-xl text-center">
                <div>
                  <Link
                    className="text-red-500"
                    to="/components/CurrentYear/TwentyTwentyTwoTopTen"
                  >
                    1 - 10
                  </Link>{" "}
                  |{" "}
                  <Link
                    className="hover:text-red-500"
                    to="/components/CurrentYear/TwentyTwentyTwoTopTwenty"
                  >
                    11 - 20
                  </Link>{" "}
                  |{" "}
                  <Link
                    className="hover:text-red-500"
                    to="/components/CurrentYear/TwentyTwentyTwoTopThirty"
                  >
                    21 - 30
                  </Link>{" "}
                  |{" "}
                  <Link
                    className="hover:text-red-500"
                    to="/components/CurrentYear/TwentyTwentyTwoTopForty"
                  >
                    31 - 40
                  </Link>{" "}
                  |{" "}
                  <Link
                    className="hover:text-red-500"
                    to="/components/CurrentYear/TwentyTwentyTwoTopFifty"
                  >
                    41 - 50
                  </Link>
                </div>

                {/* ALBUM STARTS HERE */}
                {this.state.albums.map((album) => (
                  <Album key={album.albumId} album={album} />
                ))}
              </div>
            </main>
            <aside className="w-full sm:w-1/3 md:w-1/6 px-2">
              <div className="fixed sm:top-20 md:top-10 p-4 w-full">
                <iframe
                  title="TopTen2022"
                  src="https://open.spotify.com/embed/playlist/5d5T5g8UzB4bs7Dz70ztx0"
                  width="340"
                  height="800"
                  frameBorder="0"
                  allowtransparency="true"
                  allow="encrypted-media"
                ></iframe>
              </div>
            </aside>
          </div>
        </div>
      </div>
    );
  }
}

export default TwentyTwentyTwoTopTen;
