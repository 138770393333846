import React from "react";
import Album from "./Albums/AlbumUnranked";
import years from "../../../data/years.json";
import Navbar from "../../Navbar/Navbar";
import Albums from "../../../data/PreviousYears/2015/SleptOn2015.json";
//import getEPList from "../generateEPList";
//import getStarred from "../generateRankingList";

class SleptOn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      albums: Albums,
    };
    //getEPList()
    //getStarred()
    //console.log(JSON.stringify(rankingArray));
  }

  render() {
    return (
      <div className="flex justify-left bg-gray-50">
        <div className="flex flex-wrap justify-left font-display w-full pl-6">
          {years.map((year) => (
            <Navbar year={year} yearPrime="2015" key={year.year} />
          ))}
          <div className="flex w-5/5 pt-4 pr-40">
            <div className="fixed border-solid border-4 border-black-500">
              <iframe
                title="SleptOn2015"
                src="https://open.spotify.com/embed/playlist/0HW3lmrouPD03hmo3skHh7"
                width="340"
                height="760"
                frameBorder="0"
                allowtransparency="true"
                allow="encrypted-media"
              ></iframe>
            </div>

            <div className="pl-20 pt-40 ml-80">
              <div className="font-display">
                <div className="text-7xl text-left pb-10">
                  Slept-On Albums of 2015
                </div>
                <div className="text-xl">
                  I can't believe I missed these! Circumstances in 2015 were
                  guaranteed to make me miss some fantastic releases. Below are
                  albums I discovered after making my top 50 list. These albums
                  likely would have been on the list (Aldous Harding's Designer
                  would have been in the top 5). Definitely check them out.
                </div>

                {/* ALBUM STARTS HERE */}
                {this.state.albums.map((album) => (
                  <Album key={album.albumId} album={album} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SleptOn;
