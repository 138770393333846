import React from "react";
import Album from "./Albums/Album";
import { Link } from "react-router-dom";
import Navbar from "../../Navbar/NavbarAlt";
import uuid from "uuid/v4";
import albums from "../../../data/PreviousYears/2020/TopFifty2020.json";

let topForty = albums.slice(30, 40);

class TwentyTwentyTopForty extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      albums: topForty,
    };
  }

  render() {
    return (
      <div>
        <Navbar yearPrime="2020" key={uuid} />

        <div className="container">
          <div className="flex flex-row flex-wrap py-10">
            <main role="main" class="w-full sm:w-2/3 md:w-5/6 pt-10 px-10">
              <div className="font-display text-7xl">
                <div className="text-7xl text-center pb-10">
                  50 Best Albums of 2020
                </div>
                <div className="pt-14 pb-10 text-xl text-center">
                  <div>
                    <Link
                      className="hover:text-red-500"
                      to="/components/PreviousYears/2020/TwentyTwentyTopTen"
                    >
                      1 - 10
                    </Link>{" "}
                    |{" "}
                    <Link
                      className="hover:text-red-500"
                      to="/components/PreviousYears/2020/TwentyTwentyTopTwenty"
                    >
                      11 - 20
                    </Link>{" "}
                    |{" "}
                    <Link
                      className="hover:text-red-500"
                      to="/components/PreviousYears/2020/TwentyTwentyTopThirty"
                    >
                      21 - 30
                    </Link>{" "}
                    |{" "}
                    <Link
                      className="text-red-500"
                      to="/components/PreviousYears/2020/TwentyTwentyTopForty"
                    >
                      31 - 40
                    </Link>{" "}
                    |{" "}
                    <Link
                      className="hover:text-red-500"
                      to="/components/PreviousYears/2020/TwentyTwentyTopFifty"
                    >
                      41 - 50
                    </Link>
                  </div>

                  {/* ALBUM STARTS HERE */}
                  <div className="text-left">
                    {this.state.albums.map((album) => (
                      <Album key={album.albumId} album={album} />
                    ))}
                  </div>
                </div>
              </div>
            </main>
            <aside className="w-full sm:w-1/3 md:w-1/6 px-2">
              <div className="fixed sm:top-20 md:top-10 p-4">
                <iframe
                  title="TopForty2020"
                  src="https://open.spotify.com/embed/playlist/4pVV3sJeUJYS9IRfmwawGd"
                  width="340"
                  height="800"
                  frameBorder="0"
                  allowtransparency="true"
                  allow="encrypted-media"
                ></iframe>
              </div>
            </aside>
          </div>
        </div>
      </div>
    );
  }
}

export default TwentyTwentyTopForty;
