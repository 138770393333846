import React from "react";
import Album from "./Albums/Singles";
import uuid from "uuid/v4";
import Navbar from "../../Navbar/NavbarAlt";
import Albums from "../../../data/PreviousYears/2017/Singles2017.json";
//import getEPList from "../generateEPList";
//import getStarred from "../generateRankingList";

class Singles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      albums: Albums,
    };
    //getEPList()
    //getStarred()
    //console.log(JSON.stringify(rankingArray));
  }

  render() {
    return (
      <div>
        <Navbar yearPrime="2017" key={uuid} />

        <div className="container">
          <div className="flex flex-row flex-wrap py-10">
            <main role="main" class="w-full sm:w-2/3 md:w-5/6 pt-10 px-10">
              <div className="font-display text-7xl">
                <div className="text-7xl text-center pb-10">
                  Best Singles of 2017
                </div>
                <div className="text-xl">
                  This list of singles was cobbled together in 2023, since
                  re-factoring my site meant a lot of old years have to be
                  re-built from scratch. Enjoy!
                </div>
                {/* ALBUM STARTS HERE */}
                <div className="text-left">
                  {this.state.albums.map((album) => (
                    <Album key={album.albumId} album={album} />
                  ))}
                </div>
              </div>
            </main>
            <aside className="w-full sm:w-1/3 md:w-1/6 px-2">
              <div className="fixed sm:top-20 md:top-10 p-4">
                <iframe
                  title="Singles2017"
                  src="https://open.spotify.com/embed/playlist/0csHUu7kFLwdygLrV3Yh2h"
                  width="340"
                  height="800"
                  frameBorder="0"
                  allowtransparency="true"
                  allow="encrypted-media"
                ></iframe>
              </div>
            </aside>
          </div>
        </div>
      </div>
    );
  }
}

export default Singles;
