import React from "react";
import Album from "./Albums/Album";
import { Link } from "react-router-dom";
import uuid from "uuid/v4";
import Navbar from "../../Navbar/NavbarAlt";
import albums from "../../../data/PreviousYears/2016/TopFifty2016.json";

let topTen = albums.slice(0, 10);

class TwentySixteenTopTen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      albums: topTen,
    };
  }

  render() {
    return (
      <div>
        <Navbar yearPrime="2016" key={uuid} />

        <div className="container">
          <div className="flex flex-row flex-wrap py-10">
            <main
              role="main"
              class="w-full sm:w-2/3 md:w-5/6 md:pt-10 sm:pt-20 px-10"
            >
              <div className="font-display text-7xl">The Top Ten</div>
              <div className="text-xl">
                <p>
                  When one closely observes how music changes from year to year,
                  and matches the pulse of culture and politics, patterns
                  emerge. When I reflect on the year, I see what a lot of
                  critics see: Less consensus on top picks, more embracing of
                  weird, challenging styles and niche audiences, and most
                  importantly, it is albums by women that showcase 2016's most
                  essential expression of the chaos, uncertainty and, maybe,
                  hope for the future.
                </p>
                <p>
                  Some numbers: I listened to 185 albums, had 90 standout
                  favorites that were whittled down to 50 (which means you
                  shouldn't sleep on anything that didn't make the Top 50). I
                  marked these favorites with a star &#9733;. According to
                  Spotify, I listened to over 84,000 minutes and my favorite
                  genre was Art Pop (this is not a surprise).
                </p>
                <p>
                  I rated these albums before reading any top 10 lists. I am
                  surprised how close I matched with the Guardian. See how my
                  selections match up to the{" "}
                  <a
                    className="text-red-500"
                    href="https://www.nytimes.com/2016/12/05/arts/music/best-albums.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    NYTimes
                  </a>
                  ,
                  <a
                    className="text-red-500"
                    href="https://pitchfork.com/features/lists-and-guides/best-albums-2016/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Pitchfork
                  </a>
                  ,{" "}
                  <a
                    className="text-red-500"
                    href="https://www.npr.org/2016/12/11/778225628/the-25-best-albums-of-2016"
                    target="_blank"
                    rel="noreferrer"
                  >
                    NPR
                  </a>
                  ,{" "}
                  <a
                    className="text-red-500"
                    href="https://consequenceofsound.net/2016/12/top-albums-of-2016/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Consequence Of Sound
                  </a>
                  ,
                  <a
                    className="text-red-500"
                    href="https://www.stereogum.com/featured/best-albums-2016/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Stereogum
                  </a>
                  ,{" "}
                  <a
                    className="text-red-500"
                    href="https://www.theguardian.com/music/2016/dec/03/50-best-albums-of-2016"
                    target="_blank"
                    rel="noreferrer"
                  >
                    The Guardian
                  </a>
                  , and{" "}
                  <a
                    className="text-red-500"
                    href="https://www.youtube.com/watch?v=q8wm4QFCqhE"
                    target="_blank"
                    rel="noreferrer"
                  >
                    The Needle Drop
                  </a>
                </p>
              </div>
              <div className="pt-14 pb-10 text-xl text-center">
                <div>
                  <Link
                    className="text-red-500"
                    to="/components/PreviousYears/2016/TwentySixteenTopTen"
                  >
                    1 - 10
                  </Link>{" "}
                  |{" "}
                  <Link
                    className="hover:text-red-500"
                    to="/components/PreviousYears/2016/TwentySixteenTopTwenty"
                  >
                    11 - 20
                  </Link>{" "}
                  |{" "}
                  <Link
                    className="hover:text-red-500"
                    to="/components/PreviousYears/2016/TwentySixteenTopThirty"
                  >
                    21 - 30
                  </Link>{" "}
                  |{" "}
                  <Link
                    className="hover:text-red-500"
                    to="/components/PreviousYears/2016/TwentySixteenTopForty"
                  >
                    31 - 40
                  </Link>{" "}
                  |{" "}
                  <Link
                    className="hover:text-red-500"
                    to="/components/PreviousYears/2016/TwentySixteenTopFifty"
                  >
                    41 - 50
                  </Link>
                </div>

                {/* ALBUM STARTS HERE */}
                {this.state.albums.map((album) => (
                  <Album key={album.albumId} album={album} />
                ))}
              </div>
            </main>
            <aside className="w-full sm:w-1/3 md:w-1/6 px-2">
              <div className="fixed sm:top-20 md:top-10 p-4 w-full">
                <iframe
                  title="TopTen2016"
                  src="https://open.spotify.com/embed/playlist/1F4jxlSMdU9DFgXhqb4Uwc"
                  width="340"
                  height="800"
                  frameBorder="0"
                  allowtransparency="true"
                  allow="encrypted-media"
                ></iframe>
              </div>
            </aside>
          </div>
        </div>
      </div>
    );
  }
}

export default TwentySixteenTopTen;
