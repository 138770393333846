//import SpotifyWebApi from "spotify-web-api-node";
import request from "request";
//import axios from "axios"

function getReview(artist, album) {
  return new Promise(function (resolve, reject) {
    var url = `https://bnz6evxoik.execute-api.us-east-1.amazonaws.com/prd`;
    var propertiesObject = { artist: artist, album: album };
    const headers = {
      "Content-Type": "application/json",
      "X-Api-Key": "ZbjhIvVrVG6E31uUMSDN11vvL4rzBINy54yABhvK",
    };

    request.get(
      { url: url, qs: propertiesObject, headers: headers },
      function (err, response, body) {
        if (err) {
          console.log(`getReview error ${err}`);
          return;
        }
        //console.log("Get response: " + response.statusCode);
        console.log("pitchfork text: " + body);
        resolve(body);
      }
    );
  });
}

export default getReview;
