import React from "react";
import Sidebar from "../PreviousYears/2023/Sidebar";
import Main from "../PreviousYears/2023/Main";
import Crossfade from "react-crossfade-responsive";
//import Crossfade from "../../CustomCrossfade";
import coverList from "../../data/PreviousYears/2023/coverURLs2023.json";
//import coverList from "../../../data/PreviousYears/2023/coverURLsInfo2023.json";
import Navbar from "../Navbar/NavbarAlt";
import uuid from "uuid/v4";

//import getRankingList from "../generateRankingList";




//let rankingArray = getRankingList(coverArray);

/* let rankingArray = covers.map(function (ranking) {
  let starr = ranking.starred;
  if (starr === 1) {
    return [ranking.albumId];
  }
}); */


let idArray = coverList
  .map((value) => ({ value, sort: Math.random() }))
  .sort((a, b) => a.sort - b.sort)
  .map(({ value }) => value);

const lastModified = document.lastModified;

class MusicaObscura extends React.Component {
  constructor(props) {
    //console.log(`inherited props: ${JSON.stringify(props)}`);
    super(props);
    this.state = {
      ids: "",
      albumCovers: [],
    };
    //this.getCover();
    //console.log(`album ID: ${this.state.album.albumId}`);
  }
  /* 
  getCover = async () => {
    try {
      await getAlbum(this.state.album.albumId)
        .then((data) => {
          var coverUrl = data.images[1].url;
          var lineItem = {
            cover: coverUrl,
          };
          this.setState({ albumCovers: lineItem });
          this.state.albumCovers.push(lineItem)
        });
    } catch (err) {
      console.log(err);
    }
  }; */

  render() {
    return (
      <div className="bg-gray-50">
        <header className="flex flex-wrap fixed justify-left font-display w-full pl-6 bg-black py-1">
          <div className="mx-2 pt-2 text-white">{"Year"}</div>
          <Navbar yearPrime="2023" key={uuid} />
        </header>

        {/* Left Column */}
        <div className="flex flex-wrap border-b border-t border-black pb-4 pt-10">
          <div className="w-3/3 mx-4 sm:invisible md:invisible lg:visible">
            <div className="slideshow">
              <Crossfade images={idArray} height="560px" width="560px" />
              <div className="float-left">
                Page Last Updated:&emsp; {lastModified}
              </div>
            </div>
          </div>
        </div>

        {/* Main Center */}
        <div className="flex flex-wrap w-full text-center pl-3 pr-3">
          <div className="text-6xl pb-4 font-bold border-b-2 border-red-500">
            2023 so far...
          </div>
          <Main />
        </div>

        {/* right column */}
        <div className="flex w-3/3">
          <Sidebar />
        </div>
      </div>
    );
  }
}

export default MusicaObscura;
