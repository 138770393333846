import { Route, Switch } from "react-router-dom";
import MusicTwentyNineteen from "../../components/PreviousYears/2019/music2019";
import TwentyNineteenTopTen from "../../components/PreviousYears/2019/TwentyNineteenTopTen";
import TwentyNineteenTopTwenty from "../../components/PreviousYears/2019/TwentyNineteenTopTwenty";
import TwentyNineteenTopThirty from "../../components/PreviousYears/2019/TwentyNineteenTopThirty";
import TwentyNineteenTopForty from "../../components/PreviousYears/2019/TwentyNineteenTopForty";
import TwentyNineteenTopFifty from "../../components/PreviousYears/2019/TwentyNineteenTopFifty";
import SleptOn from "../../components/PreviousYears/2019/SleptOn";
import Singles from "../../components/PreviousYears/2019/Singles";
import Favorites from "../../components/PreviousYears/2019/Favorites";

const routes = (
  <Switch>
    <Route
      path="/components/PreviousYears/2019/music2019"
      component={MusicTwentyNineteen}
    />
    <Route
      path="/components/PreviousYears/2019/TwentyNineteenTopTen"
      component={TwentyNineteenTopTen}
    />
    <Route
      path="/components/PreviousYears/2019/TwentyNineteenTopTwenty"
      component={TwentyNineteenTopTwenty}
    />
    <Route
      path="/components/PreviousYears/2019/TwentyNineteenTopThirty"
      component={TwentyNineteenTopThirty}
    />
    <Route
      path="/components/PreviousYears/2019/TwentyNineteenTopForty"
      component={TwentyNineteenTopForty}
    />
    <Route
      path="/components/PreviousYears/2019/TwentyNineteenTopFifty"
      component={TwentyNineteenTopFifty}
    />
    <Route path="/components/PreviousYears/2019/SleptOn" component={SleptOn} />
    <Route path="/components/PreviousYears/2019/Singles" component={Singles} />
    <Route
      path="/components/PreviousYears/2019/Favorites"
      component={Favorites}
    />
  </Switch>
);

export default routes;
