import React from "react";
import { Link } from "react-router-dom";

function Main() {
  return (
    <div className="grid gap-x-4 gap-y-4 grid-cols-3 text-6xl border-b-2 border-black">
      <div>
        <Link
          className="hover:text-red-500"
          to="/components/PreviousYears/2023/Months/Dec"
        >
          Dec
        </Link>
      </div>
      <div>
        <Link
          className="hover:text-red-500"
          to="/components/PreviousYears/2023/Months/Jan"
        >
          Jan
        </Link>
      </div>
      <div>
        <Link
          className="hover:text-red-500"
          to="/components/PreviousYears/2023/Months/Feb"
        >
          Feb
        </Link>
      </div>
      <div>
        <Link
          className="hover:text-red-500"
          to="/components/PreviousYears/2023/Months/Mar"
        >
          Mar
        </Link>
      </div>

      <div>
        <Link
          className="hover:text-red-500"
          to="/components/PreviousYears/2023/Months/Apr"
        >
          Apr
        </Link>
      </div>
      <div>
        <Link
          className="hover:text-red-500"
          to="/components/PreviousYears/2023/Months/May"
        >
          May
        </Link>
      </div>
      <div>
        <Link
          className="hover:text-red-500"
          to="/components/PreviousYears/2023/Months/Jun"
        >
          Jun
        </Link>
      </div>

      <div>
        <Link
          className="hover:text-red-500"
          to="/components/PreviousYears/2023/Months/Jul"
        >
          Jul
        </Link>
      </div>
      <div>
        <Link
          className="hover:text-red-500"
          to="/components/PreviousYears/2023/Months/Aug"
        >
          Aug
        </Link>
      </div>

      <div>
        <Link
          className="hover:text-red-500"
          to="/components/PreviousYears/2023/Months/Sep"
        >
          Sep
        </Link>
      </div>

      <div>
        <Link
          className="hover:text-red-500"
          to="/components/PreviousYears/2023/Months/Oct"
        >
          Oct
        </Link>
      </div>
      <div>
        <Link
          className="hover:text-red-500"
          to="/components/PreviousYears/2023/Months/Nov"
        >
          Nov
        </Link>
      </div>
    </div>
  );
}

export default Main;
