import React from "react";
import getAlbum from "../../getAlbum";
import getArtist from "../../getArtist";

/* function ArtistsLogic(arts) {
  //console.log(`just essentials: ${JSON.stringify(ess)}`);
  //console.log(`show last song: ${JSON.stringify(lastSong)}`);
  //console.log(`last: ${JSON.stringify(ess.last)}`);
  let lineEnder = "";
  if (ess.essential.song !== ess.last) {
    lineEnder = ", ";
  } else {
    lineEnder = "";
  }

  let cName = "";
  let fullA = "";

  if (ess.essential.youtubeUrl !== "") {
    cName = "text-red-500";
    fullA = (
      <a
        className={cName}
        href={ess.essential.youtubeUrl}
        target="_blank"
        rel="noreferrer"
      >
        {ess.essential.song}
        {lineEnder}
      </a>
    );
  } else {
    cName = "text-black-500";
    fullA = ess.essential.song + lineEnder;
  }

  //console.log(fullA);
  return fullA;
} */

class Singles extends React.Component {
  constructor(props) {
    //console.log(`inherited props: ${JSON.stringify(props)}`);
    super(props);
    this.state = {
      album: props.album,
      albumMetadata: {},
      artistMetadata: {},
    };
    this.getAlb();
    //console.log(`album ID: ${this.state.album.albumId}`);
  }

  getAlb = async () => {
    try {
      await getAlbum(this.state.album.albumId)
        .then((data) => {
          let artistList = data.artists
            .map((artisto) => artisto.name)
            .join(", ");
          var coverUrl = data.images[1].url;
          var albumName = data.name;
          var artistId = data.artists[0].id;
          var lineItem = {
            artist: artistList,
            cover: coverUrl,
            name: albumName,
            artistId: artistId,
          };
          this.setState({ albumMetadata: lineItem });
        })
        .then((data) => {
          //console.log(`artistID: ${this.state.albumMetadata.artistId}`);
          getArtist(this.state.albumMetadata.artistId).then((data) => {
            var artist = data.name.toString();
            var genres = data.genres.join(", ");
            var lineItem = {
              artist: artist,
              genres: genres,
            };
            this.setState({ artistMetadata: lineItem });
          });
        });
    } catch (err) {
      console.log(err);
    }
    /*     console.log(
      `did we make object available: ${JSON.stringify(
        this.state.albumMetadata
      )}` 
    );*/
  };

  render() {
    return (
      <div className="pt-20 pb-5">
        <div className="border-t-2 border-gray"></div>

        <div className="text-center">
          <h4 className="pt-5 mb-2 uppercase text-gray-500 tracking-widest text-xs">
            {this.state.artistMetadata.genres}
          </h4>
          <p className="text-gray-600 mb-2 text-4xl">
            {this.state.albumMetadata.artist}{" "}
            <span className="text-red-500">||</span>{" "}
            {this.state.album.essentials[0].song}
          </p>
          <div className="flex mt-3 justify-center">
            <iframe
              title={this.state.album.essentials[0].song}
              src={this.state.album.essentials[0].youtubeUrl}
              width="760"
              height="430"
              frameBorder="0"
              allowtransparency="true"
              allow="encrypted-media"
            ></iframe>
          </div>
        </div>
      </div>
    );
  }
}

export default Singles;
