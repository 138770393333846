function Sidebar() {
  return (
    <iframe
      title="BestOf2016"
      src="https://embed.spotify.com/?uri=spotify:playlist:6vqMxPGVMCba74lX5rFSwn"
      width="340"
      height="100%"
      frameBorder="0"
      allowtransparency="true"
      allow="encrypted-media"
    ></iframe>
  );
}

export default Sidebar;
