import { Route, Switch } from "react-router-dom";
import MusicTwentyThirteen from "../../components/PreviousYears/2013/music2013";
import TwentyThirteenTopTen from "../../components/PreviousYears/2013/TwentyThirteenTopTen";
import TwentyThirteenTopTwenty from "../../components/PreviousYears/2013/TwentyThirteenTopTwenty";
import TwentyThirteenTopThirty from "../../components/PreviousYears/2013/TwentyThirteenTopThirty";
import TwentyThirteenTopForty from "../../components/PreviousYears/2013/TwentyThirteenTopForty";
import TwentyThirteenTopFifty from "../../components/PreviousYears/2013/TwentyThirteenTopFifty";
import SleptOn from "../../components/PreviousYears/2013/SleptOn";
import Singles from "../../components/PreviousYears/2013/Singles";
import Favorites from "../../components/PreviousYears/2013/Favorites";

const routes = (
  <Switch>
    <Route
      path="/components/PreviousYears/2013/music2013"
      component={MusicTwentyThirteen}
    />
    <Route
      path="/components/PreviousYears/2013/TwentyThirteenTopTen"
      component={TwentyThirteenTopTen}
    />
    <Route
      path="/components/PreviousYears/2013/TwentyThirteenTopTwenty"
      component={TwentyThirteenTopTwenty}
    />
    <Route
      path="/components/PreviousYears/2013/TwentyThirteenTopThirty"
      component={TwentyThirteenTopThirty}
    />
    <Route
      path="/components/PreviousYears/2013/TwentyThirteenTopForty"
      component={TwentyThirteenTopForty}
    />
    <Route
      path="/components/PreviousYears/2013/TwentyThirteenTopFifty"
      component={TwentyThirteenTopFifty}
    />
    <Route path="/components/PreviousYears/2013/SleptOn" component={SleptOn} />
    <Route path="/components/PreviousYears/2013/Singles" component={Singles} />
    <Route
      path="/components/PreviousYears/2013/Favorites"
      component={Favorites}
    />
  </Switch>
);

export default routes;
