import React from "react";
import Album from "./Albums/Album";
import { Link } from "react-router-dom";
import years from "../../../data/years.json";
import Navbar from "../../Navbar/Navbar";
import albums from "../../../data/PreviousYears/2014/TopFifty2014.json";

let topForty = albums.slice(30, 40);

class TwentyFourteenTopForty extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      albums: topForty,
    };
  }

  render() {
    return (
      <div className="flex justify-left bg-gray-50">
        <div className="flex flex-wrap justify-left font-display w-full pl-6">
          {years.map((year) => (
            <Navbar year={year} yearPrime="2014" key={year.year} />
          ))}
          <div className="flex w-5/5 pt-4 pr-40">
            <div className="fixed border-solid border-4 border-black-500">
              <iframe
                title="TopForty2014"
                src="https://open.spotify.com/embed/playlist/1Szj1vAlpNvWFrhy2te7Tk"
                width="340"
                height="800"
                frameBorder="0"
                allowtransparency="true"
                allow="encrypted-media"
              ></iframe>
            </div>
            <div className="pl-20 pt-40 ml-80">
              <div className="font-display text-7xl">
                <div className="text-7xl text-left pb-10">
                  Best 50 Albums of 2014
                </div>
                <div className="pt-14 pb-10 text-xl text-center">
                  <div>
                    <Link
                      className="hover:text-red-500"
                      to="/components/PreviousYears/2014/TwentyFourteenTopTen"
                    >
                      1 - 10
                    </Link>{" "}
                    |{" "}
                    <Link
                      className="hover:text-red-500"
                      to="/components/PreviousYears/2014/TwentyFourteenTopTwenty"
                    >
                      11 - 20
                    </Link>{" "}
                    |{" "}
                    <Link
                      className="hover:text-red-500"
                      to="/components/PreviousYears/2014/TwentyFourteenTopThirty"
                    >
                      21 - 30
                    </Link>{" "}
                    |{" "}
                    <Link
                      className="text-red-500"
                      to="/components/PreviousYears/2014/TwentyFourteenTopForty"
                    >
                      31 - 40
                    </Link>{" "}
                    |{" "}
                    <Link
                      className="hover:text-red-500"
                      to="/components/PreviousYears/2014/TwentyFourteenTopFifty"
                    >
                      41 - 50
                    </Link>
                  </div>
                </div>
                {/* ALBUM STARTS HERE */}
                {this.state.albums.map((album) => (
                  <Album key={album.album} album={album} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TwentyFourteenTopForty;
