import React from "react";
import getAlbum from "../../../getAlbum";
import getArtist from "../../../getArtist";

class CoverSongs extends React.Component {
  constructor(props) {
    //console.log(`inherited props: ${JSON.stringify(props)}`);
    super(props);
    this.state = {
      album: props.album,
      albumMetadata: {},
      artistMetadata: {},
    };
    this.getAlb();
    //console.log(`album ID: ${this.state.album.albumId}`);
  }

  getAlb = async () => {
    try {
      await getAlbum(this.state.album.albumId)
        .then((data) => {
          var artist = data.artists[0].name.toString();
          var coverUrl = data.images[1].url;
          var albumName = data.name;
          var artistId = data.artists[0].id;
          var lineItem = {
            artist: artist,
            cover: coverUrl,
            name: albumName,
            artistId: artistId,
          };
          this.setState({ albumMetadata: lineItem });
        })
        .then((data) => {
          //console.log(`artistID: ${this.state.albumMetadata.artistId}`);
          getArtist(this.state.albumMetadata.artistId).then((data) => {
            var artist = data.name.toString();
            var genres = data.genres.join(", ");
            var lineItem = {
              artist: artist,
              genres: genres,
            };
            this.setState({ artistMetadata: lineItem });
          });
        });
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    return (
      <div className="pt-20 pb-10 text-left">
        <div className="border-t-2 border-gray"></div>
        <div className="pt-8">
          <img
            className="float-left mr-4"
            width="280px"
            height="280px"
            src={this.state.albumMetadata.cover}
            alt=""
          />
        </div>
        <div className="ml-20">
          <div>
            <h1 className="mt-0 mb-2 text-black text-6xl">
              {this.state.album.essentials[0].song}
            </h1>
          </div>
          <p className="text-gray-600 mb-2 text-4xl">
            {this.state.album.coverArtist}
          </p>
          <p className="text-gray-600 mb-2 text-xl">
            Original song by {this.state.album.originalArtist}
          </p>
        </div>
      </div>
    );
  }
}

export default CoverSongs;
