import React from "react";
import { Link } from "react-router-dom";
import Album from "./Album/Album";
import Navbar from "../../Navbar/NavbarAlt";
import uuid from "uuid/v4";
import TopFifty from "../../../data/PreviousYears/2021/2021Top50.json";
//import getEPList from "../generateEPList";
//import getStarred from "../generateRankingList";

let topTen = TopFifty.slice(0, 10);

class TwentyTwentyOneTopTen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      albums: topTen,
    };
    //getEPList()
    //getStarred()
    //console.log(JSON.stringify(rankingArray));
  }

  render() {
    return (
      <div>
        <Navbar yearPrime="2021" key={uuid} />

        <div className="container">
          <div className="flex flex-row flex-wrap py-10">
            <main
              role="main"
              class="w-full sm:w-2/3 md:w-5/6 md:pt-10 sm:pt-20 px-10"
            >
              <div className="font-display text-7xl">The Top Ten</div>
              <div className="text-xl">
                What can be said about 2021 that hasn't already been said by a
                million therapists, shut-ins and weirdos? My trend of not
                listening to enough new music was rivaled by the huge amount of
                old music I listened to. My most listend to album was
                Sevdaliza's
                <a
                  className="text-red-500"
                  href="https://pitchfork.com/reviews/albums/sevdaliza-shabrang/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  Shabrang
                </a>{" "}
                from 2020 (which if you haven't listend to, do that now). I
                would like to say I stretched my wings this year, but I
                continued to find my favorite music of the year in lonely,
                introspective and plain weird albums. I picked the title of this
                year's curation from the song Scratchcard Lanyard, off of Dry
                Cleaning's stellar debut album "
                <a
                  className="text-red-500"
                  href="https://pitchfork.com/reviews/albums/dry-cleaning-new-long-leg/"
                  target="_blank"
                  rel="noreferrer"
                >
                  New Long Leg
                </a>
                ."
                <br />
                Some numbers: I listened to 141 albums and EPs, had 74 standout
                favorites that were whittled down to 50 (which means you
                shouldn't sleep on anything that didn't make the Top 50). I
                marked these favorites with a star ★. According to Spotify, I
                listened to over 219,660 minutes and my favorite genre was Art
                Pop (for the 4th year in a row).
                <br />I rated these albums before reading any top 10 lists. I
                found my closest match in Consequence of Sound's list, but there
                was a lot of variation in top album lists this year. See how my
                selections match up to the{" "}
                <a
                  className="text-red-500"
                  href="https://www.nytimes.com/2021/12/02/arts/music/best-pop-albums.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  NYTimes
                </a>
                ,{" "}
                <a
                  className="text-red-500"
                  href="https://pitchfork.com/features/lists-and-guides/best-albums-2021/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Pitchfork
                </a>
                ,{" "}
                <a
                  className="text-red-500"
                  href="https://www.npr.org/2021/12/01/1054318397/the-50-best-albums-of-2021-page-1"
                  target="_blank"
                  rel="noreferrer"
                >
                  NPR
                </a>
                ,{" "}
                <a
                  className="text-red-500"
                  href="https://consequence.net/2021/12/top-50-albums-2021-list/9/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Consequence Of Sound
                </a>
                ,{" "}
                <a
                  className="text-red-500"
                  href="https://www.stereogum.com/2169206/the-50-best-albums-of-2021/lists/year-in-review/2021-in-review/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Stereogum
                </a>
                ,{" "}
                <a
                  className="text-red-500"
                  href="https://www.theguardian.com/music/2021/nov/30/the-50-best-albums-of-2021"
                  target="_blank"
                  rel="noreferrer"
                >
                  The Guardian
                </a>
                , and{" "}
                <a
                  className="text-red-500"
                  href="https://youtu.be/LhMqqAK5eAc"
                  target="_blank"
                  rel="noreferrer"
                >
                  The Needle Drop
                </a>
              </div>
              <div className="pt-14 pb-10 text-xl text-center">
                <div>
                  <Link
                    className="text-red-500"
                    to="/components/PreviousYears/2021/TwentyTwentyOneTopTen"
                  >
                    1 - 10
                  </Link>{" "}
                  |{" "}
                  <Link
                    className="hover:text-red-500"
                    to="/components/PreviousYears/2021/TwentyTwentyOneTopTwenty"
                  >
                    11 - 20
                  </Link>{" "}
                  |{" "}
                  <Link
                    className="hover:text-red-500"
                    to="/components/PreviousYears/2021/TwentyTwentyOneTopThirty"
                  >
                    21 - 30
                  </Link>{" "}
                  |{" "}
                  <Link
                    className="hover:text-red-500"
                    to="/components/PreviousYears/2021/TwentyTwentyOneTopForty"
                  >
                    31 - 40
                  </Link>{" "}
                  |{" "}
                  <Link
                    className="hover:text-red-500"
                    to="/components/PreviousYears/2021/TwentyTwentyOneTopFifty"
                  >
                    41 - 50
                  </Link>
                </div>

                {/* ALBUM STARTS HERE */}
                {this.state.albums.map((album) => (
                  <Album key={album.albumId} album={album} />
                ))}
              </div>
            </main>
            <aside className="w-full sm:w-1/3 md:w-1/6 px-2">
              <div className="fixed sm:top-20 md:top-10 p-4 w-full">
                <iframe
                  title="TopTen2021"
                  src="https://open.spotify.com/embed/playlist/6ZfreFPHVqvu1ZYRxdZ6n5"
                  width="340"
                  height="800"
                  frameBorder="0"
                  allowtransparency="true"
                  allow="encrypted-media"
                ></iframe>
              </div>
            </aside>
          </div>
        </div>
      </div>
    );
  }
}

export default TwentyTwentyOneTopTen;
