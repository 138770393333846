import React from "react";
import getAlbum from "../../getAlbum";
import getArtist from "../../getArtist";
import getReview from "../../getReview";

function EssentialLogic(ess) {
  //console.log(`just essentials: ${JSON.stringify(ess)}`);
  //console.log(`show last song: ${JSON.stringify(lastSong)}`);
  //console.log(`last: ${JSON.stringify(ess.last)}`);
  let lineEnder = "";
  if (ess.essential.song !== ess.last) {
    lineEnder = ", ";
  } else {
    lineEnder = "";
  }

  let cName = "";
  let fullA = "";

  if (ess.essential.youtubeUrl !== "") {
    cName = "text-red-500";
    fullA = (
      <a
        className={cName}
        href={ess.essential.youtubeUrl}
        target="_blank"
        rel="noreferrer"
      >
        {ess.essential.song}
        {lineEnder}
      </a>
    );
  } else {
    cName = "text-black-500";
    fullA = ess.essential.song + lineEnder;
  }

  //console.log(fullA);
  return fullA;
}

function Starred(starTrue) {
  console.log(JSON.stringify(starTrue));
  if (starTrue.starr === 1) {
    return "★ ";
  } else {
    return "";
  }
}

class AlbumAuto extends React.Component {
  constructor(props) {
    //console.log(`inherited props: ${JSON.stringify(props)}`);
    super(props);
    this.state = {
      album: props.album,
      albumMetadata: {},
      artistMetadata: {},
      reviewMetadata: {},
    };
    this.getAlb();
    //console.log(`album ID: ${this.state.album.albumId}`);
  }

  getAlb = async () => {
    try {
      await getAlbum(this.state.album.albumId)
        .then((data) => {
          var artist = data.artists[0].name.toString();
          var coverUrl = data.images[1].url;
          var albumName = data.name;
          var artistId = data.artists[0].id;
          var lineItem = {
            artist: artist,
            cover: coverUrl,
            name: albumName,
            artistId: artistId,
          };
          this.setState({ albumMetadata: lineItem });
        })
        .then((data) => {
          //console.log(`artistID: ${this.state.albumMetadata.artistId}`);
          getArtist(this.state.albumMetadata.artistId).then((data) => {
            var artist = data.name.toString();
            var genres = data.genres.join(", ");
            var lineItem = {
              artist: artist,
              genres: genres,
            };
            this.setState({ artistMetadata: lineItem });
          });
        })
        .then((data) => {
          getReview(
            this.state.albumMetadata.artist,
            this.state.albumMetadata.name
          ).then((data) => {
            console.log(`Anything? ${data}`);
            let rURL = "";
            let rReview = "";
            let lineItem = "";
            if (data.includes("AlbumNotFound")) {
              rURL = this.state.album.reviewUrl;
              rReview = this.state.album.albumText;
              lineItem = {
                url: rURL,
                review: rReview,
              };
              console.log(lineItem);
            } else {
              let pData = JSON.parse(data);
              rURL = pData.url;
              rReview = pData.desc;
              lineItem = {
                url: rURL,
                review: rReview,
              };
              console.log(lineItem);
            }
            this.setState({ reviewMetadata: lineItem });
          });
        });
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    let lastSong = this.state.album.essentials.at(-1).song;
    const spotifyId = `https://open.spotify.com/embed/album/${this.state.album.albumId}`;
    return (
      <div grid gap-x-8 gap-y-4 grid-cols-1>
        <div className="pt-20">
          <div className="border-t-2 border-gray"></div>
          <div className="pt-8">
            <img
              className="float-left mr-4 box-border h-600px w-400px pb-16"
              width="400px"
              height="400px"
              src={this.state.albumMetadata.cover}
              alt=""
            />
          </div>
          <div>
            <div>
              <h4 className="mt-0 mb-2 uppercase text-gray-500 tracking-widest text-xs">
                {this.state.artistMetadata.genres}
              </h4>
              <div className="ml-30">
                <h1 className="mt-0 mb-2 text-black text-5xl border-b-2 border-red-500 clear:none">
                  {this.state.albumMetadata.artist}
                </h1>
              </div>
              <p className="text-gray-600 mb-2 text-4xl">
                {this.state.albumMetadata.name}
              </p>
              <iframe
                title={this.state.album.albumId}
                src={spotifyId}
                frameBorder="0"
                allowtransparency="true"
                allow="encrypted-media"
                width="280"
                height="80"
              ></iframe>
              <div className="mt-3">
                <p className="text-gray-600 text-base">
                  <Starred starr={this.state.album.starred} />
                  {this.state.reviewMetadata.review} ||{" "}
                  <em>
                    <a
                      className="text-red-500"
                      href={this.state.reviewMetadata.url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Full Review
                    </a>
                  </em>
                </p>
                <p className="text-sm pt-5">
                  Essentials:{" "}
                  {this.state.album.essentials.map((essential) => (
                    <EssentialLogic
                      key={essential.song}
                      essential={essential}
                      last={lastSong}
                    />
                  ))}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AlbumAuto;
