import React from "react";
import { Link } from "react-router-dom";

function Main() {
  return (
    <div className="text-6xl text-center pt-4 pb-4 mx-1 font-bold">
      <Link
        className="hover:text-red-500"
        to="/components/CurrentYear/TwentyTwentyTwoTopTen"
      >
        TOP 50 Albums
      </Link>

      <div className="text-4xl font-normal pt-10 lg:grid text-center gap-x-6 gap-y-4 grid-cols-2">
        <div>
          <Link
            className="hover:text-red-500"
            to="/components/CurrentYear/Favorites"
          >
            Favorites
          </Link>
        </div>
        <div>
          <Link className="hover:text-red-500" to="/components/CurrentYear/EPs">
            EPs
          </Link>
        </div>
        <div>
          <Link
            className="hover:text-red-500"
            to="/components/CurrentYear/Covers"
          >
            Covers
          </Link>
        </div>
        <div>
          <Link
            className="hover:text-red-500"
            to="/components/CurrentYear/Singles"
          >
            Singles
          </Link>
        </div>
        <div className="col-span-2 text-align:center">
          <Link
            className="hover:text-red-500"
            to="/components/CurrentYear/SleptOn"
          >
            Slept On
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Main;
