import { Route, Switch } from "react-router-dom";
import MuOb from "../../components/PreviousYears/MusicaObscura";

const routes = (
  <Switch>
    <Route path="/components/PreviousYears/MusicaObscura" component={MuOb} />
  </Switch>
);

export default routes;
