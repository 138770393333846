import React from "react";
import Album from "./Albums/AlbumUnranked";
import uuid from "uuid/v4";
import Navbar from "../../Navbar/NavbarAlt";
import Albums from "../../../data/PreviousYears/2020/SleptOn2020.json";
//import getEPList from "../generateEPList";
//import getStarred from "../generateRankingList";

class SleptOn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      albums: Albums,
    };
    //getEPList()
    //getStarred()
    //console.log(JSON.stringify(rankingArray));
  }

  render() {
    return (
      <div>
        <Navbar yearPrime="2020" key={uuid} />

        <div className="container">
          <div className="flex flex-row flex-wrap py-10">
            <main role="main" class="w-full sm:w-2/3 md:w-5/6 pt-10 px-10">
              <div className="font-display text-7xl">
                <div className="text-7xl text-center pb-10">
                  Slept-On Albums of 2020
                </div>
                <div className="text-xl">
                  I can't believe I missed these! Circumstances in 2020 was
                  guaranteed to make me miss some fantastic releases. Below are
                  albums I discovered after making my top 50 list. These albums
                  likely would have been on the list. Definitely check them out.
                </div>
                {/* ALBUM STARTS HERE */}
                <div className="text-left">
                  {this.state.albums.map((album) => (
                    <Album key={album.albumId} album={album} />
                  ))}
                </div>
              </div>
            </main>
            <aside className="w-full sm:w-1/3 md:w-1/6 px-2">
              <div className="fixed sm:top-20 md:top-10 p-4">
                <iframe
                  title="SleptOn2020"
                  src="https://open.spotify.com/embed/playlist/3oKwCeDCeh7nw51cxgC62K"
                  width="340"
                  height="800"
                  frameBorder="0"
                  allowtransparency="true"
                  allow="encrypted-media"
                ></iframe>
              </div>
            </aside>
          </div>
        </div>
      </div>
    );
  }
}

export default SleptOn;
