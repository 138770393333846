import React from "react";
import { Link } from "react-router-dom";

class NavbarAlt extends React.Component {
  constructor(props) {
    //console.log(`inherited props: ${JSON.stringify(props)}`);
    super(props);
    this.state = {
      year: props.year,
      yearPrime: props.yearPrime,
      currentYear: "2022",
    };
    //console.log(`album ID: ${this.state.album.albumId}`);
  }

  render() {
    let buttonStyle =
      "bg-gray-50 text-black py-2 px-4 mr-6 rounded inline-flex items-center";
    let ulStyle =
      "dropdown-menu absolute hidden text-black bg-gray-50 pt-1 py-2 px-4";
    let liStyle =
      "dropdown-item text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-gray-50 text-gray-700 hover:bg-gray-100";
    return (
      <header className="flex fixed font-display w-full pl-6 bg-black justify-between z-50">
        <div className="text-white py-3">
          <Link className="hover:text-red-500" to="/">
            KANE CURATED
          </Link>
        </div>
        <div className="dropdown relative pt-1">
          <button className={buttonStyle}>
            <span className="mr-1">{this.state.yearPrime}</span>
            <svg
              className="fill-current h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />{" "}
            </svg>
          </button>
          <ul className={ulStyle}>
            <li className={liStyle}>
              <Link
                className="hover:text-red-500"
                to="/components/PreviousYears/2023/music2023"
              >
                2023
              </Link>
            </li>
            <li className={liStyle}>
              <Link className="hover:text-red-500" to="/">
                2022
              </Link>
            </li>
            <li className={liStyle}>
              <Link
                className="hover:text-red-500"
                to="/components/PreviousYears/2021/music2021"
              >
                2021
              </Link>
            </li>
            <li className={liStyle}>
              <Link
                className="hover:text-red-500"
                to="/components/PreviousYears/2020/music2020"
              >
                2020
              </Link>
            </li>
            <li className={liStyle}>
              <Link
                className="hover:text-red-500"
                to="/components/PreviousYears/2019/music2019"
              >
                2019
              </Link>
            </li>
            <li className={liStyle}>
              <Link
                className="hover:text-red-500"
                to="/components/PreviousYears/2018/music2018"
              >
                2018
              </Link>
            </li>
            <li className={liStyle}>
              <Link
                className="hover:text-red-500"
                to="/components/PreviousYears/2017/music2017"
              >
                2017
              </Link>
            </li>
            <li className={liStyle}>
              <Link
                className="hover:text-red-500"
                to="/components/PreviousYears/2016/music2016"
              >
                2016
              </Link>
            </li>
            <li className={liStyle}>
              <Link
                className="hover:text-red-500"
                to="/components/PreviousYears/2015music2015"
              >
                2015
              </Link>
            </li>
            <li className={liStyle}>
              <Link
                className="hover:text-red-500"
                to="/components/PreviousYears/2014/music2014"
              >
                2014
              </Link>
            </li>
            <li className={liStyle}>
              <Link
                className="hover:text-red-500"
                to="/components/PreviousYears/2013/music2013"
              >
                2013
              </Link>
            </li>
            <li className={liStyle}>
              <Link
                className="hover:text-red-500"
                to="/components/PreviousYears/2012/music2012"
              >
                2012
              </Link>
            </li>
          </ul>
        </div>
      </header>
    );
  }
}

export default NavbarAlt;
