import { Route, Switch } from "react-router-dom";
import MusicTwentyTwenty from "../../components/PreviousYears/2020/music2020";
import TwentyTwentyTopTen from "../../components/PreviousYears/2020/TwentyTwentyTopTen";
import TwentyTwentyTopTwenty from "../../components/PreviousYears/2020/TwentyTwentyTopTwenty";
import TwentyTwentyTopThirty from "../../components/PreviousYears/2020/TwentyTwentyTopThirty";
import TwentyTwentyTopForty from "../../components/PreviousYears/2020/TwentyTwentyTopForty";
import TwentyTwentyTopFifty from "../../components/PreviousYears/2020/TwentyTwentyTopFifty";
import EPs from "../../components/PreviousYears/2020/EPs";
import SleptOn from "../../components/PreviousYears/2020/SleptOn";
import Singles from "../../components/PreviousYears/2020/Singles";
import Favorites from "../../components/PreviousYears/2020/Favorites";

const routes = (
  <Switch>
    <Route
      path="/components/PreviousYears/2020/music2020"
      component={MusicTwentyTwenty}
    />
    <Route
      path="/components/PreviousYears/2020/TwentyTwentyTopTen"
      component={TwentyTwentyTopTen}
    />
    <Route
      path="/components/PreviousYears/2020/TwentyTwentyTopTwenty"
      component={TwentyTwentyTopTwenty}
    />
    <Route
      path="/components/PreviousYears/2020/TwentyTwentyTopThirty"
      component={TwentyTwentyTopThirty}
    />
    <Route
      path="/components/PreviousYears/2020/TwentyTwentyTopForty"
      component={TwentyTwentyTopForty}
    />
    <Route
      path="/components/PreviousYears/2020/TwentyTwentyTopFifty"
      component={TwentyTwentyTopFifty}
    />
    <Route path="/components/PreviousYears/2020/EPs" component={EPs} />
    <Route path="/components/PreviousYears/2020/SleptOn" component={SleptOn} />
    <Route path="/components/PreviousYears/2020/Singles" component={Singles} />
    <Route
      path="/components/PreviousYears/2020/Favorites"
      component={Favorites}
    />
  </Switch>
);

export default routes;
