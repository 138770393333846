import { Route, Switch } from "react-router-dom";
import MusicTwentyFourteen from "../../components/PreviousYears/2014/music2014";
import TwentyFourteenTopTen from "../../components/PreviousYears/2014/TwentyFourteenTopTen";
import TwentyFourteenTopTwenty from "../../components/PreviousYears/2014/TwentyFourteenTopTwenty";
import TwentyFourteenTopThirty from "../../components/PreviousYears/2014/TwentyFourteenTopThirty";
import TwentyFourteenTopForty from "../../components/PreviousYears/2014/TwentyFourteenTopForty";
import TwentyFourteenTopFifty from "../../components/PreviousYears/2014/TwentyFourteenTopFifty";
import SleptOn from "../../components/PreviousYears/2014/SleptOn";
import Singles from "../../components/PreviousYears/2014/Singles";
import Favorites from "../../components/PreviousYears/2014/Favorites";

const routes = (
  <Switch>
    <Route
      path="/components/PreviousYears/2014/music2014"
      component={MusicTwentyFourteen}
    />
    <Route
      path="/components/PreviousYears/2014/TwentyFourteenTopTen"
      component={TwentyFourteenTopTen}
    />
    <Route
      path="/components/PreviousYears/2014/TwentyFourteenTopTwenty"
      component={TwentyFourteenTopTwenty}
    />
    <Route
      path="/components/PreviousYears/2014/TwentyFourteenTopThirty"
      component={TwentyFourteenTopThirty}
    />
    <Route
      path="/components/PreviousYears/2014/TwentyFourteenTopForty"
      component={TwentyFourteenTopForty}
    />
    <Route
      path="/components/PreviousYears/2014/TwentyFourteenTopFifty"
      component={TwentyFourteenTopFifty}
    />
    <Route path="/components/PreviousYears/2014/SleptOn" component={SleptOn} />
    <Route path="/components/PreviousYears/2014/Singles" component={Singles} />
    <Route
      path="/components/PreviousYears/2014/Favorites"
      component={Favorites}
    />
  </Switch>
);

export default routes;
