import React from "react";
import Sidebar from "./Sidebar";
import Main from "./Main";
import Crossfade from "react-crossfade-responsive";
import coverList from "../../../data/PreviousYears/2014/coverURLs2014.json";
import years from "../../../data/years.json";
import Navbar from "../../Navbar/Navbar";
import albums from "../../../data/PreviousYears/2014/TopFifty2014.json";
import getCoverList from "../../generateCoverList";

let coverArray = albums.map(function (element) {
  return element.albumId;
});

//console.log(JSON.stringify(coverArray));

let idArray = coverList
  .map((value) => ({ value, sort: Math.random() }))
  .sort((a, b) => a.sort - b.sort)
  .map(({ value }) => value);

const lastModified = document.lastModified;

//getCoverList()

class TwentyFourteen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ids: coverArray,
      albumCovers: [],
    };
    //this.getCoverList()
    //console.log(`album ID: ${thisd.state.album.albumId}`);
  }

  render() {
    return (
      <div className="flex justify-left bg-gray-50">
        <div className="flex flex-wrap justify-left font-display w-full pl-6">
          {years.map((year) => (
            <Navbar year={year} yearPrime="2014" key={year.year} />
          ))}
          <div className="flex border-b border-t border-black pb-4 pt-4 mx-4">
            <div className="w-3/3 border-solid border-4 border-black-500">
              <Sidebar />
            </div>

            {/* Main Center */}
            <div className="w-full text-center pl-3 pr-3">
              <div className="text-5xl pb-4 font-bold border-b-2 border-red-500">
              μ  s  i  c   2014
              </div>
              <Main />
            </div>

            {/* right column */}
            <div className="flex w-3/3 pl-6">
              <div className="slideshow">
                <Crossfade images={idArray} height="560px" width="560px" />
                <div className="float-right">
                  Page Last Updated:&emsp; {lastModified}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TwentyFourteen;
