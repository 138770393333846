import React from "react";
import Sidebar from "./Sidebar";
import Main from "./Main";
import Crossfade from "react-crossfade-responsive";
import coverList from "../../../data/PreviousYears/2019/coverURLs2019.json";
import uuid from "uuid/v4";
import Navbar from "../../Navbar/NavbarAlt";
import albums from "../../../data/PreviousYears/2019/TopFifty2019.json";
import getCoverList from "../../generateCoverList";

let coverArray = albums.map(function (element) {
  return element.albumId;
});

//console.log(JSON.stringify(coverArray));

let idArray = coverList
  .map((value) => ({ value, sort: Math.random() }))
  .sort((a, b) => a.sort - b.sort)
  .map(({ value }) => value);

const lastModified = document.lastModified;

//getCoverList()

class TwentyNineteen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ids: coverArray,
      albumCovers: [],
    };
    //this.getCoverList()
    //console.log(`album ID: ${thisd.state.album.albumId}`);
  }

  render() {
    return (
      <div>
        <Navbar yearPrime="2019" key={uuid} />

        {/* Left Column */}
        <div className="max-w-7xl grid grid-cols-10 pt-10">
          <aside className="pt-4 col-span-5 mx-2 sm:invisible md:invisible lg:visible">
            <div className="slideshow">
              <Crossfade images={idArray} height="100%" width="100%" />
            </div>
          </aside>

          {/* Main Center */}
          <div className="col-span-4 pt-4 px-4">
            <div className="text-center">
              <div className="text-5xl pb-4 font-bold border-b-2 border-red-500">
                Translate your love into action
              </div>
              <Main />
            </div>
            <div className="text-center">
              Page Last Updated:&emsp; {lastModified}
            </div>
          </div>

          {/* right column */}
          <div className="pt-4 col-span-1">
            <aside>
              <Sidebar />
            </aside>
          </div>
        </div>
      </div>
    );
  }
}

export default TwentyNineteen;
