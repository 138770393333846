import React from "react";
import Album from "./Albums/Album";
import { Link } from "react-router-dom";
import uuid from "uuid/v4";
import Navbar from "../../Navbar/NavbarAlt";
import albums from "../../../data/PreviousYears/2018/TopFifty2018.json";

let topTen = albums.slice(0, 10);

class TwentyEighteenTopTen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      albums: topTen,
    };
  }

  render() {
    return (
      <div>
        <Navbar yearPrime="2018" key={uuid} />

        <div className="container">
          <div className="flex flex-row flex-wrap py-10">
            <main
              role="main"
              class="w-full sm:w-2/3 md:w-5/6 md:pt-10 sm:pt-20 px-10"
            >
              <div className="font-display text-7xl">The Top Ten</div>
              <div className="text-xl">
                <p>
                  How is it possible that 2018 felt longer than 2017? The
                  political and social queasiness that defined the year is
                  undeniably present in the year's music. Unpredictable,
                  genre-busting, and present as hell. Identity and art are
                  intertwined in 2018's music more than ever before. Half of the
                  top ten are debut albums, and it's no coincidence that fifteen
                  of the top 20 albums are fronted by women. The chaos and
                  corruption that has become quotidian has also birthed some of
                  the best music of the decade.
                </p>
                <p>
                  I expanded the scope of review and of the number of albums
                  listened to in 2018. See how my selections match up to the
                  <a
                    className="text-red-500"
                    href="https://www.nytimes.com/2018/12/06/arts/music/best-albums.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    NYTimes
                  </a>
                  ,{" "}
                  <a
                    className="text-red-500"
                    href="https://pitchfork.com/features/lists-and-guides/the-50-best-albums-of-2018/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Pitchfork
                  </a>
                  ,{" "}
                  <a
                    className="text-red-500"
                    href="https://www.npr.org/2018/12/04/671242319/the-50-best-albums-of-2018-page-5"
                    target="_blank"
                    rel="noreferrer"
                  >
                    NPR
                  </a>
                  ,{" "}
                  <a
                    className="text-red-500"
                    href="https://consequenceofsound.net/2018/12/top-50-albums-of-2018/full-post/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Consequence Of Sound
                  </a>
                  ,
                  <a
                    className="text-red-500"
                    href="https://www.stereogum.com/featured/the-best-albums-of-2018/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Stereogum
                  </a>
                  , and{" "}
                  <a
                    className="text-red-500"
                    href="https://www.pastemagazine.com/articles/2018/11/the-50-best-albums-of-2018.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Paste Magazine
                  </a>
                </p>
              </div>
              <div className="pt-14 pb-10 text-xl text-center">
                <div>
                  <Link
                    className="text-red-500"
                    to="/components/PreviousYears/2018/TwentyEighteenTopTen"
                  >
                    1 - 10
                  </Link>{" "}
                  |{" "}
                  <Link
                    className="hover:text-red-500"
                    to="/components/PreviousYears/2018/TwentyEighteenTopTwenty"
                  >
                    11 - 20
                  </Link>{" "}
                  |{" "}
                  <Link
                    className="hover:text-red-500"
                    to="/components/PreviousYears/2018/TwentyEighteenTopThirty"
                  >
                    21 - 30
                  </Link>{" "}
                  |{" "}
                  <Link
                    className="hover:text-red-500"
                    to="/components/PreviousYears/2018/TwentyEighteenTopForty"
                  >
                    31 - 40
                  </Link>{" "}
                  |{" "}
                  <Link
                    className="hover:text-red-500"
                    to="/components/PreviousYears/2018/TwentyEighteenTopFifty"
                  >
                    41 - 50
                  </Link>
                </div>

                {/* ALBUM STARTS HERE */}
                {this.state.albums.map((album) => (
                  <Album key={album.albumId} album={album} />
                ))}
              </div>
            </main>
            <aside className="w-full sm:w-1/3 md:w-1/6 px-2">
              <div className="fixed sm:top-20 md:top-10 p-4 w-full">
                <iframe
                  title="TopTen2018"
                  src="https://open.spotify.com/embed/playlist/1F4jxlSMdU9DFgXhqb4Uwc"
                  width="340"
                  height="800"
                  frameBorder="0"
                  allowtransparency="true"
                  allow="encrypted-media"
                ></iframe>
              </div>
            </aside>
          </div>
        </div>
      </div>
    );
  }
}

export default TwentyEighteenTopTen;
