import React from "react";
import Album from "./Albums/Album";
import { Link } from "react-router-dom";
import years from "../../../data/years.json";
import Navbar from "../../Navbar/Navbar";
import albums from "../../../data/PreviousYears/2014/TopFifty2014.json";

let topTen = albums.slice(0, 10);

class TwentyFourteenTopTen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      albums: topTen,
    };
  }

  render() {
    return (
      <div className="flex justify-left bg-gray-50">
        <div className="flex flex-wrap justify-left font-display w-full pl-6">
          {years.map((year) => (
            <Navbar year={year} yearPrime="2014" key={year.year} />
          ))}
          <div className="flex w-5/5 pt-4 pr-40">
            <div className="fixed border-solid border-4 border-black-500">
              <iframe
                title="TopTen2014"
                src="https://open.spotify.com/embed/playlist/6juvttwkKYzbvsY5NGcPmn"
                width="340"
                height="800"
                frameBorder="0"
                allowtransparency="true"
                allow="encrypted-media"
              ></iframe>
            </div>

            <div className="pl-20 pt-40 ml-80">
              <div className="font-display text-7xl">
                The Top Ten
                <div className="text-xl">
                  <p>
                    When one closely observes how music changes from year to
                    year, and matches the pulse of culture and politics,
                    patterns emerge. When I reflect on the year, I see what a
                    lot of critics see: Less consensus on top picks, more
                    embracing of weird, challenging styles and niche audiences,
                    and most importantly, it is albums by women that showcase
                    2014's most essential expression of the chaos, uncertainty
                    and, maybe, hope for the future.
                  </p>
                  <p>
                    Some numbers: I listened to 185 albums, had 90 standout
                    favorites that were whittled down to 50 (which means you
                    shouldn't sleep on anything that didn't make the Top 50). I
                    marked these favorites with a star &#9733;. According to
                    Spotify, I listened to over 84,000 minutes and my favorite
                    genre was Art Pop (this is not a surprise).
                  </p>
                  <p>
                    I rated these albums before reading any top 10 lists. I am
                    surprised how close I matched with the Guardian. See how my
                    selections match up to the{" "}
                    <a
                      className="text-red-500"
                      href="https://www.nytimes.com/2014/12/05/arts/music/best-albums.html"
                      target="_blank"
                      rel="noreferrer"
                    >
                      NYTimes
                    </a>
                    ,
                    <a
                      className="text-red-500"
                      href="https://pitchfork.com/features/lists-and-guides/best-albums-2014/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Pitchfork
                    </a>
                    ,{" "}
                    <a
                      className="text-red-500"
                      href="https://www.npr.org/2014/12/11/778225628/the-25-best-albums-of-2014"
                      target="_blank"
                      rel="noreferrer"
                    >
                      NPR
                    </a>
                    ,{" "}
                    <a
                      className="text-red-500"
                      href="https://consequenceofsound.net/2014/12/top-albums-of-2014/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Consequence Of Sound
                    </a>
                    ,
                    <a
                      className="text-red-500"
                      href="https://www.stereogum.com/featured/best-albums-2014/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Stereogum
                    </a>
                    ,{" "}
                    <a
                      className="text-red-500"
                      href="https://www.theguardian.com/music/2014/dec/03/50-best-albums-of-2014"
                      target="_blank"
                      rel="noreferrer"
                    >
                      The Guardian
                    </a>
                    , and{" "}
                    <a
                      className="text-red-500"
                      href="https://www.youtube.com/watch?v=q8wm4QFCqhE"
                      target="_blank"
                      rel="noreferrer"
                    >
                      The Needle Drop
                    </a>
                  </p>
                </div>
                <div className="pt-14 pb-10 text-xl text-center">
                  <div>
                    <Link
                      className="text-red-500"
                      to="/components/PreviousYears/2014/TwentyFourteenTopTen"
                    >
                      1 - 10
                    </Link>{" "}
                    |{" "}
                    <Link
                      className="hover:text-red-500"
                      to="/components/PreviousYears/2014/TwentyFourteenTopTwenty"
                    >
                      11 - 20
                    </Link>{" "}
                    |{" "}
                    <Link
                      className="hover:text-red-500"
                      to="/components/PreviousYears/2014/TwentyFourteenTopThirty"
                    >
                      21 - 30
                    </Link>{" "}
                    |{" "}
                    <Link
                      className="hover:text-red-500"
                      to="/components/PreviousYears/2014/TwentyFourteenTopForty"
                    >
                      31 - 40
                    </Link>{" "}
                    |{" "}
                    <Link
                      className="hover:text-red-500"
                      to="/components/PreviousYears/2014/TwentyFourteenTopFifty"
                    >
                      41 - 50
                    </Link>
                  </div>
                </div>
                {/* ALBUM STARTS HERE */}
                {this.state.albums.map((album) => (
                  <Album key={album.album} album={album} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TwentyFourteenTopTen;
