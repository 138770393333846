import { Route, Switch } from "react-router-dom";
import Home from "../CurrentYear/music2022";
//import MusicTwentyTwentyTwo from "../../components/PreviousYears/2021/music2021";
import TwentyTwentyTwoTopTen from "../../components/CurrentYear/TwentyTwentyTwoTopTen";
import TwentyTwentyTwoTopTwenty from "../../components/CurrentYear/TwentyTwentyTwoTopTwenty";
import TwentyTwentyTwoTopThirty from "../../components/CurrentYear/TwentyTwentyTwoTopThirty";
import TwentyTwentyTwoTopForty from "../../components/CurrentYear/TwentyTwentyTwoTopForty";
import TwentyTwentyTwoTopFifty from "../../components/CurrentYear/TwentyTwentyTwoTopFifty";
import EPs from "../../components/CurrentYear/EPs";
import Covers from "../../components/CurrentYear/Covers";
import Singles from "../../components/CurrentYear/Singles";
import Favorites from "../../components/CurrentYear/Favorites";
import SleptOn from "../../components/CurrentYear/SleptOn";

const routes = (
  <Switch>
    <Route exact path="/" component={Home} />
    <Route
      path="/components/CurrentYear/TwentyTwentyTwoTopTen"
      component={TwentyTwentyTwoTopTen}
    />
    <Route
      path="/components/CurrentYear/TwentyTwentyTwoTopTwenty"
      component={TwentyTwentyTwoTopTwenty}
    />
    <Route
      path="/components/CurrentYear/TwentyTwentyTwoTopThirty"
      component={TwentyTwentyTwoTopThirty}
    />
    <Route
      path="/components/CurrentYear/TwentyTwentyTwoTopForty"
      component={TwentyTwentyTwoTopForty}
    />
    <Route
      path="/components/CurrentYear/TwentyTwentyTwoTopFifty"
      component={TwentyTwentyTwoTopFifty}
    />
    <Route path="/components/CurrentYear/EPs" component={EPs} />
    <Route path="/components/CurrentYear/Covers" component={Covers} />
    <Route path="/components/CurrentYear/Singles" component={Singles} />
    <Route path="/components/CurrentYear/Favorites" component={Favorites} />
    <Route path="/components/CurrentYear/SleptOn" component={SleptOn} />
  </Switch>
);

export default routes;
