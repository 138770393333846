import React from "react";
import { Link } from "react-router-dom";

class Navbar extends React.Component {
  constructor(props) {
    //console.log(`inherited props: ${JSON.stringify(props)}`);
    super(props);
    this.state = {
      year: props.year,
      yearPrime: props.yearPrime,
      currentYear: "2022",
    };
    //console.log(`album ID: ${this.state.album.albumId}`);
  }

  render() {
    let fullLinkCss = "";
    let fullLiCss = "";
    let linkPath = "";

    if (this.state.year === this.state.currentYear) {
      linkPath = "/";
    } else {
      linkPath = `/components/PreviousYears/${this.state.year}/music${this.state.year}`;
    }

    if (this.state.year === this.state.yearPrime) {
      //page year
      fullLiCss = `"-mb-px mr-1"`;
      fullLinkCss = `"bg-gray-100 inline-block border-gray-300 border-2 border-r-4 border-b-4 rounded-t py-2 px-4 text-red"`;
      console.log(`current year: ${this.state.year}`);
    } else {
      //not current year
      fullLiCss = `"mr-1"`;
      fullLinkCss = `"bg-gray-100 inline-block py-2 px-4 text-black"`;
      console.log(`Previous year: ${this.state.year}`);
    }

    return (
      <div className="sticky top-0 bg-gray-50 sm:overflow-x-auto">
        <ul className="text-sm sm:flex-wrap md:flex lg:flex">
          <li className={fullLiCss}>
            <div className={fullLinkCss}>
              <Link className="hover:text-red-500" to={linkPath}>
                {this.state.year}
              </Link>
            </div>
          </li>
        </ul>
      </div>
    ); //bg-white inline-block py-2 px-4 text-blue-500 hover:text-black
  }
}

export default Navbar;
