import React from "react";
import Album from "./Album/AlbumUnranked";
import Navbar from "../Navbar/NavbarAlt";
import uuid from "uuid/v4";
import TopEPs from "../../data/CurrentYear/EPs2022.json";
//import getEPList from "../generateEPList";
//import getStarred from "../generateRankingList";

class EPs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      albums: TopEPs,
    };
    //getEPList()
    //getStarred()
    //console.log(JSON.stringify(rankingArray));
  }

  render() {
    return (
      <div>
        <Navbar yearPrime="2022" key={uuid} />

        <div className="container">
          <div className="flex flex-row flex-wrap py-10">
            <main role="main" class="w-full sm:w-2/3 md:w-5/6 pt-10 px-10">
              <div className="font-display text-7xl">
                <div className="text-7xl text-center pb-10">
                  Best EPs of 2022
                </div>
                <div className="text-xl">
                  EP curation was a bit haphazard this year, but Sevdaliza's
                  Raving Dahlia is so good. Also, Squirrel Flower's cover of
                  Björk's 'unravel' is great.
                </div>
                {/* ALBUM STARTS HERE */}
                <div className="text-left">
                  {this.state.albums.map((album) => (
                    <Album key={album.albumId} album={album} />
                  ))}
                </div>
              </div>
            </main>
            <aside className="w-full sm:w-1/3 md:w-1/6 px-2">
              <div className="fixed sm:top-20 md:top-10 p-4">
                <iframe
                  title="TopEPs2022"
                  src="https://open.spotify.com/embed/playlist/0qlcoycuMLb2znxkzC34TF"
                  width="340"
                  height="800"
                  frameBorder="0"
                  allowtransparency="true"
                  allow="encrypted-media"
                ></iframe>
              </div>
            </aside>
          </div>
        </div>
      </div>
    );
  }
}

export default EPs;
