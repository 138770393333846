import React from "react";
import { Link } from "react-router-dom";

class Navmonth extends React.Component {
  constructor(props) {
    //console.log(`inherited props: ${JSON.stringify(props)}`);
    super(props);
    this.state = {
      month: props.month,
      monthPrime: props.monthPrime,
    };
    //console.log(`album ID: ${this.state.album.albumId}`);
  }

  render() {
    let fullLinkCss = "";
    let fullLiCss = "";
    let linkPath = "";

    linkPath = `/components/PreviousYears/2023/Months/${this.state.month}`;

    if (this.state.month === this.state.monthPrime) {
      //page year
      fullLiCss = `"-mb-px mr-1"`;
      fullLinkCss = `"inline-block border-gray-300 border-2 border-r-4 border-b-4 rounded-t py-2 px-4 text-red"`;
      //console.log(`current year: ${this.state.year}`);
    } else {
      //not current year
      fullLiCss = `"mr-1"`;
      fullLinkCss = `"inline-block py-2 px-4 text-black"`;
      //console.log(`Previous year: ${this.state.year}`);
    }

    return (
      <div className="sticky pt-12 bg-white">
        <ul className="flex text-sm w-100%">
          <li className={fullLiCss}>
            <div className={fullLinkCss}>
              <Link className="hover:text-red-500" to={linkPath}>
                {this.state.month}
              </Link>
            </div>
          </li>
        </ul>
      </div>
    ); //bg-white inline-block py-2 px-4 text-blue-500 hover:text-black
  }
}

export default Navmonth;
