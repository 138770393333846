import { Route, Switch } from "react-router-dom";
import MusicTwentyFifteen from "../../components/PreviousYears/2015/music2015";
import TwentyFifteenTopTen from "../../components/PreviousYears/2015/TwentyFifteenTopTen";
import TwentyFifteenTopTwenty from "../../components/PreviousYears/2015/TwentyFifteenTopTwenty";
import TwentyFifteenTopThirty from "../../components/PreviousYears/2015/TwentyFifteenTopThirty";
import TwentyFifteenTopForty from "../../components/PreviousYears/2015/TwentyFifteenTopForty";
import TwentyFifteenTopFifty from "../../components/PreviousYears/2015/TwentyFifteenTopFifty";
import SleptOn from "../../components/PreviousYears/2015/SleptOn";
import Singles from "../../components/PreviousYears/2015/Singles";
import Favorites from "../../components/PreviousYears/2015/Favorites";

const routes = (
  <Switch>
    <Route
      path="/components/PreviousYears/2015/music2015"
      component={MusicTwentyFifteen}
    />
    <Route
      path="/components/PreviousYears/2015/TwentyFifteenTopTen"
      component={TwentyFifteenTopTen}
    />
    <Route
      path="/components/PreviousYears/2015/TwentyFifteenTopTwenty"
      component={TwentyFifteenTopTwenty}
    />
    <Route
      path="/components/PreviousYears/2015/TwentyFifteenTopThirty"
      component={TwentyFifteenTopThirty}
    />
    <Route
      path="/components/PreviousYears/2015/TwentyFifteenTopForty"
      component={TwentyFifteenTopForty}
    />
    <Route
      path="/components/PreviousYears/2015/TwentyFifteenTopFifty"
      component={TwentyFifteenTopFifty}
    />
    <Route path="/components/PreviousYears/2015/SleptOn" component={SleptOn} />
    <Route path="/components/PreviousYears/2015/Singles" component={Singles} />
    <Route
      path="/components/PreviousYears/2015/Favorites"
      component={Favorites}
    />
  </Switch>
);

export default routes;
