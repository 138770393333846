import React from "react";
import Sidebar from "./Sidebar";
import Main from "./Main";
import Crossfade from "react-crossfade-responsive";
//import Crossfade from "../../CustomCrossfade";
import coverList from "../../../data/PreviousYears/2023/coverURLs2023.json";
//import coverList from "../../../data/PreviousYears/2023/coverURLsInfo2023.json";
import Navbar from "../../Navbar/NavbarAlt";
import uuid from "uuid/v4";
import coversDec from "../../../data/PreviousYears/2023/Months/Dec2022.json";
import coversJan from "../../../data/PreviousYears/2023/Months/Jan2023.json";
import coversFeb from "../../../data/PreviousYears/2023/Months/Feb2023.json";
import coversMar from "../../../data/PreviousYears/2023/Months/Mar2023.json";
import coversApr from "../../../data/PreviousYears/2023/Months/Apr2023.json";
import coversMay from "../../../data/PreviousYears/2023/Months/May2023.json";
import coversJun from "../../../data/PreviousYears/2023/Months/Jun2023.json";
import coversJul from "../../../data/PreviousYears/2023/Months/Jul2023.json";
import coversAug from "../../../data/PreviousYears/2023/Months/Aug2023.json";
import coversSep from "../../../data/PreviousYears/2023/Months/Sep2023.json";
import coversOct from "../../../data/PreviousYears/2023/Months/Oct2023.json";
import coversNov from "../../../data/PreviousYears/2023/Months/Nov2023.json";
//import getRankingList from "../generateRankingList";

let covers = coversJan.concat(
  coversFeb,
  coversMar,
  coversApr,
  coversMay,
  coversJun,
  coversJul,
  coversAug,
  coversSep,
  coversOct,
  coversNov,
  coversDec
);

let coverArray = covers.map(function (element) {
  return element.albumId;
});

//let rankingArray = getRankingList(coverArray);

/* let rankingArray = covers.map(function (ranking) {
  let starr = ranking.starred;
  if (starr === 1) {
    return [ranking.albumId];
  }
}); */

console.log(JSON.stringify(coverArray));

let idArray = coverList
  .map((value) => ({ value, sort: Math.random() }))
  .sort((a, b) => a.sort - b.sort)
  .map(({ value }) => value);

const lastModified = document.lastModified;

class Home extends React.Component {
  constructor(props) {
    //console.log(`inherited props: ${JSON.stringify(props)}`);
    super(props);
    this.state = {
      ids: coverArray,
      albumCovers: [],
    };
    //this.getCover();
    //console.log(`album ID: ${this.state.album.albumId}`);
  }
  /* 
  getCover = async () => {
    try {
      await getAlbum(this.state.album.albumId)
        .then((data) => {
          var coverUrl = data.images[1].url;
          var lineItem = {
            cover: coverUrl,
          };
          this.setState({ albumCovers: lineItem });
          this.state.albumCovers.push(lineItem)
        });
    } catch (err) {
      console.log(err);
    }/
  }; */

  render() {
    return (
      <div>
        <Navbar yearPrime="2023" key={uuid} />

        {/* Left Column */}
        <div className="max-w-7xl grid grid-cols-10 pt-10">
          <aside className="pt-4 col-span-5 mx-2 sm:invisible md:invisible lg:visible">
            <div className="slideshow">
              <Crossfade images={idArray} height="100%" width="100%" />
            </div>
          </aside>

          {/* Main Center */}
          <div className="col-span-4 pt-4 px-4">
            <div className="text-center">
              <div className="text-6xl pb-4 font-bold border-b-2 border-red-500">
                2023 so far...
              </div>
              <Main />
            </div>
            <div className="text-center">
              Page Last Updated:&emsp; {lastModified}
            </div>
          </div>

          {/* right column */}
          <div className="pt-4 col-span-1">
            <aside>
              <Sidebar />
            </aside>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
