import React from "react";
import { Link } from "react-router-dom";

function Main() {
  return (
    <div className="text-6xl pb-4 mx-1 font-bold text-center">
      <Link
        className="hover:text-red-500"
        to="/components/PreviousYears/2016/TwentySixteenTopTen"
      >
        TOP 50 Albums
      </Link>

      <div className="grid gap-x-6 gap-y-4 grid-cols-2 text-4xl font-normal pt-10">
        <div>
          <Link
            className="hover:text-red-500"
            to="/components/PreviousYears/2016/Favorites"
          >
            Favorites
          </Link>
        </div>
        <div>
          <Link
            className="hover:text-red-500"
            to="/components/PreviousYears/2016/SleptOn"
          >
            Slept-On
          </Link>
        </div>
        <div>
          <Link
            className="hover:text-red-500"
            to="/components/PreviousYears/2016/Singles"
          >
            Singles
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Main;
