//import SpotifyWebApi from "spotify-web-api-node";
import request from "request";

function getArtist(artistID) {
  return new Promise(function (resolve, reject) {
    var client_id = "9d658bb7db7f49f2b669e2e33f2ba6bf";
    var client_secret = "76377a8172684f959afeadc535ac5e14";

    // your application requests authorization
    var authOptions = {
      url: "https://accounts.spotify.com/api/token",
      headers: {
        Authorization:
          "Basic " +
          new Buffer.from(client_id + ":" + client_secret).toString("base64"),
      },
      form: {
        grant_type: "client_credentials",
      },
      json: true,
    };

    request.post(authOptions, function (error, response, body) {
      if (!error && response.statusCode === 200) {
        // use the access token to access the Spotify Web API
        var token = body.access_token;
        var options = {
          url: `https://api.spotify.com/v1/artists/${artistID}`,
          headers: {
            Authorization: "Bearer " + token,
          },
          json: true,
        };
        request.get(options, function (error, response, body) {
          //console.log(`Internal artist received: ${JSON.stringify(body)}`);
          resolve(body);
        });
      }
    });
  });
}

export default getArtist;
